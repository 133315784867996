import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import img1 from "../../../assets/images/pages/home/homeimg3.svg";
import { useTestimonials } from "../../../redux/hooks/TestimonialsHook";
import EditedTextCmp from "../../components/EditedTextCmp";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const HomeSection5 = () => {
  const { allTestimonials } = useTestimonials();
  return (
    <div className="my-5">
      <div className="container">
        <Carousel responsive={responsive}>
          {/* ----------------------- clone --------------------- */}
          {allTestimonials.map((value, index) => (
            <div
              key={index}
              className="HomeSection5"
              style={{ backgroundImage: `url(${img1})` }}
            >
              <div className="HomeSection5_imge_box">
                <img className="HomeSection5_imge" src={value.image} alt="" />
              </div>
              <div className="HomeSection5_innerdiv">
                <h6 className="HomeSection5_testimonial">
                  <EditedTextCmp givenState={value.description} />
                </h6>
                <h6 className="HomeSection5_name">{value.name}</h6>
                <h6 className="HomeSection5_designation">{value.position}</h6>
              </div>
            </div>
          ))}

          {/* ----------------------- clone --------------------- */}
        </Carousel>
      </div>
    </div>
  );
};

export default HomeSection5;
