import { SET_CURRENT_CART } from "../constants";

const currentCartData = "";
export const currentCartReducer = (state = currentCartData, action) => {
  switch (action.type) {
    case SET_CURRENT_CART:
      return action.data;
    default:
      return state;
  }
};
