// ------------   redux ---------------------
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCategory } from "../action";
// ------------   redux ---------------------
export function useSelectedCategoryHook() {
  const dispatch = useDispatch();
  const selectedCategory = useSelector(
    (state) => state.selectedCategoryReducer
  );

  const updateselectedCategory = (data) => {
    dispatch(setSelectedCategory(data));
  };

  return { selectedCategory, updateselectedCategory };
}
