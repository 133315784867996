import { SET_FORM_DATE } from "../constants";

const formDateData = "";
export const formDateReducer = (state = formDateData, action) => {
  switch (action.type) {
    case SET_FORM_DATE:
      return action.data;
    default:
      return state;
  }
};
