import { SET_FORM_SPACKAGE } from "../constants";

const formSpackageData = "";
export const formSpackageReducer = (state = formSpackageData, action) => {
  switch (action.type) {
    case SET_FORM_SPACKAGE:
      console.log(action);

      return action.data;
    default:
      return state;
  }
};
