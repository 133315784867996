import React from "react";
import Banner from "../../components/Banner";
import { Link } from "react-router-dom";
import { useBlogHook } from "../../../redux/hooks/useBlogHook";
import Footer from "../../../layout/Footer";
import Header from "../../../layout/Header";
import "../../../assets/css/pages/blogPage.css";
import EditedTextCmp from "../../components/EditedTextCmp";

const RenderBlogs = ({ value }) => {
  return (
    <div className="mb-3">
      <Link to={`/blog/${value._id}`}>
        <div className="blogPage_item row">
          <div className="col-md-3">
            <img style={{ maxWidth: "90%" }} src={value.image} alt="" />
          </div>
          <div className="col-md-9">
            <h4 className="blogPage_item_sec1">{value.title}</h4>
            <p className="blogPage_item_sec2">
              <EditedTextCmp givenState={value.shortDesc} />
            </p>
          </div>
        </div>
      </Link>
      <hr />
    </div>
  );
};

const Blog = () => {
  const { allBlogs } = useBlogHook();
  return (
    <div>
      <Header />
      <Banner title="Blogs" showItemSlider={false} />
      <div className="container mt-5">
        <div className="container my-3">
          <h5 className="text-primary  wishlistPage_heading">
            <Link to="/" className="text-decoration-none">
              <u>Home</u>
            </Link>
            <span> | </span>
            <span>Blog</span>
          </h5>
        </div>
      </div>
      {/* -------------------------- */}
      <div className="container blog_section">
        <div className="my-5">
          {/* =========================================================== */}
          <div className="accordion" id="accordionExample">
            {allBlogs.map((value, index) => (
              <RenderBlogs key={index} value={value} index={index} />
            ))}
          </div>
          {/* =========================================================== */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
