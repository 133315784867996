import { SET_ABOUTUS } from "../constants";

const allAboutUs = [];
export const aboutusReducer = (state = allAboutUs, action) => {
  switch (action.type) {
    case SET_ABOUTUS:
      return action.data.data;
    default:
      return state;
  }
};
