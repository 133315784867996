// ------------   redux ---------------------
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPujasList } from "../action";
// ------------   redux ---------------------
export function usePujasHook() {
  const dispatch = useDispatch();
  const allPujas = useSelector((state) => state.pujasReducer);

  const updatePujasData = () => {
    dispatch(getPujasList());
  };

  useEffect(() => {
    if (allPujas.length < 1) {
      dispatch(getPujasList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { allPujas, updatePujasData };
}
