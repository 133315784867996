import { SET_BLOGS } from "../constants";

const allBlogs = [];
export const blogsReducer = (state = allBlogs, action) => {
  switch (action.type) {
    case SET_BLOGS:
      return action.data.data;
    default:
      return state;
  }
};
