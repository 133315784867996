import React from "react";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import { Link } from "react-router-dom";
import { OnboardingForm } from "./OnboardingForm";

export const NewPanditRegistration = () => {
  return (
    <div>
      <Header hideBookingSection={true} />
      <div className="container mt-5">
        <div className="container   my-3">
          <h5 className="text-primary  wishlistPage_heading">
            <Link to="/" className="text-decoration-none">
              <u>Home</u>
            </Link>
            <span> | </span>
            <span>PARTNER WITH US</span>
          </h5>
        </div>
      </div>
      {/* -------------------------- */}
      <div>
        <div className="container mb-5 mt-2">
          <h5>
            <center>
              <b style={{ fontWeight: "bold", color: "rgb(106,17,16)" }}>
                Fill the Pujanya Pandit Onboarding Form
              </b>
            </center>
          </h5>
          <div>
            <OnboardingForm />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
