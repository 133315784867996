const sendOtp = async (mobile, otp, callback, failure) => {
  const form_data = new FormData();

  form_data.append("mobile", mobile);
  form_data.append("otp", otp);

  fetch(`${process.env.REACT_APP_BACKEND_1}/auth/customer/send-otp-mobile`, {
    method: "POST",
    body: form_data,
  })
    .then((v) => v.json())
    .then((v) => {
      // console.log(v);
      callback();
    })
    .catch((err) => {
      failure();
      console.log(err.message);
    });
};

export default sendOtp;
