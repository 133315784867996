/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const TextEditorCmp = ({ givenState, givenStateSetter }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const globalOnchange = (newEditorState) => {
    setEditorState(newEditorState);
    givenStateSetter(newEditorState);
  };

  useEffect(() => {
    setTimeout(() => {
      const onChange = (newEditorState) => {
        setEditorState(newEditorState);
        givenStateSetter(newEditorState);
      };
      if (givenState && givenState !== "") {
        if (typeof givenState === "string") {
          try {
            const contentState = convertFromRaw(JSON.parse(givenState));
            const newEditorState = EditorState.createWithContent(contentState);
            onChange(newEditorState);
          } catch (e) {
            onChange(() => EditorState.createEmpty());
          }
        }
      }
    }, 400);
  }, [givenState, givenStateSetter]);
  return (
    <Editor
      editorState={editorState}
      wrapperClassName="demo-wrapper"
      editorClassName="demo-editor"
      onEditorStateChange={globalOnchange}
    />
  );
};

export default TextEditorCmp;
