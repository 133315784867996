import { SET_PANDITS } from "../constants";

const allPandits = [];
export const panditReducer = (state = allPandits, action) => {
  switch (action.type) {
    case SET_PANDITS:
      return action.data.data;
    default:
      return state;
  }
};
