// ------------   redux ---------------------
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCitiesList } from "../action";
// ------------   redux ---------------------
export function useCityHook() {
  const dispatch = useDispatch();
  const allCities = useSelector((state) => state.citiesReducer);

  const updateCitiesData = () => {
    dispatch(getCitiesList());
  };

  useEffect(() => {
    if (allCities.length < 1) {
      dispatch(getCitiesList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { allCities, updateCitiesData };
}
