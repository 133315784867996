import { SET_CITIES } from "../constants";

const allCities = [];
export const citiesReducer = (state = allCities, action) => {
  switch (action.type) {
    case SET_CITIES:
      return action.data.data;

    default:
      return state;
  }
};
