import React, { useState } from "react";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import Banner from "../../components/Banner";
import { Link } from "react-router-dom";
import "../../../assets/css/pages/contactus.css";
import { useBasicSetup } from "../../../redux/hooks/BasicSetupHook";

const ContactUs = () => {
  const { BasicDetailData } = useBasicSetup();

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [message, setmessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const form_data = new FormData();
    form_data.append("name", name);
    form_data.append("email", email);
    form_data.append("phone", phone);
    form_data.append("message", message);
    fetch(`${process.env.REACT_APP_BACKEND_1}/guest/contact_requests/save`, {
      body: form_data,
      method: "POST",
    })
      .then((v) => v.json())
      .then((v) => {
        if (v.message === "success") {
          alert("Thank you for contacting us. We will get back to you soon.");
          setname("");
          setemail("");
          setphone("");
          setmessage("");
        } else {
          alert("Something went wrong. Please try again.");
        }
      })
      .catch((err) => {
        alert("Something went wrong. Please try again.");
        console.log(err.message);
      });
  };
  return (
    <div>
      <Header />
      <Banner title="Contact Us" />
      <div className="container mt-5">
        <div className="container my-3">
          <h5 className="text-primary  wishlistPage_heading">
            <Link to="/" className="text-decoration-none">
              <u>Home</u>
            </Link>
            <span> | </span>
            <span>Contact Us</span>
          </h5>
        </div>
      </div>
      {/* -------------------------- */}
      <div className="container contact_us_mainContainer">
        <div className="my-5">
          {/* =========================================================== */}
          <div className="row">
            <div className="col-md-6">
              <div className="mb-5">
                <h2>We're here to help</h2>
                <h6>
                  Ask us anything about our products, services, and/or any
                  questions you may have.
                </h6>
                <div className="my-5">
                  <div className="mb-5">
                    <address>{BasicDetailData.address}</address>
                  </div>
                  <div className="mb-4">
                    <b>Phone Number</b>
                    <br />
                    <h5 className="contact_info">{BasicDetailData.phone}</h5>
                  </div>
                  <div>
                    <b>Email Adderss</b>
                    <br />
                    <h5 className="contact_info">{BasicDetailData.email}</h5>
                  </div>
                </div>
                <div style={{ overflow: "hidden" }}>
                  <iframe
                    src={BasicDetailData.googleMapLink}
                    width="600"
                    title="map"
                    height="450"
                    style={{ border: "0" }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-5">
                <h4>Namaste!</h4>
                <h6>Our support team will help you with your queries. </h6>
                <h6>Get in touch today! </h6>
              </div>
              <div className="my-3">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label>Name</label>
                    <input
                      type="text"
                      className="my-2"
                      value={name}
                      onChange={(e) => {
                        setname(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label>Email</label>
                    <input
                      type="email"
                      className="my-2"
                      value={email}
                      onChange={(e) => {
                        setemail(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label>Mobile</label>
                    <input
                      type="number"
                      className="my-2"
                      value={phone}
                      onChange={(e) => {
                        setphone(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label>Message</label>
                    <textarea
                      rows={5}
                      type="text"
                      className="my-2"
                      value={message}
                      onChange={(e) => {
                        setmessage(e.target.value);
                      }}
                    />
                  </div>
                  <button type="submit" className="btn">
                    Get in Touch
                  </button>
                </form>
              </div>
            </div>
          </div>
          {/* =========================================================== */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
