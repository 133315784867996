import { SET_STATES } from "../constants";

const allStates = [];
export const statesReducer = (state = allStates, action) => {
  switch (action.type) {
    case SET_STATES:
      return action.data.data;
    default:
      return state;
  }
};
