import React from "react";

const HomeSection6 = () => {
  return (
    <div className="my-5">
      <div className="container">
        <div className="home_section6">
          {/* -------------------- copy ----------------------- */}
          <div className="home_section6_item">
            <img
              className="home_section6_image"
              src={require("../../../assets/images/pages/home/homeSec6Img1.png")}
              alt=""
            />
            <h5 className="home_section6_heading">Speedy and Free Shipping</h5>
            <p className="home_section6_short_desc">
              Fast & Free Shiping Above Rs 500
            </p>
          </div>
          {/* -------------------- copy ----------------------- */}
          {/* -------------------- copy ----------------------- */}
          {/* <div className="home_section6_item">
            <img
              className="home_section6_image"
              src={require("../../../assets/images/pages/home/homeSec6Img2.png")}
              alt=""
            />
            <h5 className="home_section6_heading">Satisfied or Refund</h5>
            <p className="home_section6_short_desc">
              Fast & free above 500
            </p>
          </div> */}
          {/* -------------------- copy ----------------------- */}
          {/* -------------------- copy ----------------------- */}
          <div className="home_section6_item">
            <img
              className="home_section6_image"
              src={require("../../../assets/images/pages/home/homeSec6Img3.png")}
              alt=""
            />
            <h5 className="home_section6_heading">Best in Class Products</h5>
            <p className="home_section6_short_desc">
              We offer Best in Class Products
            </p>
          </div>
          {/* -------------------- copy ----------------------- */}
          {/* -------------------- copy ----------------------- */}
          <div className="home_section6_item">
            <img
              className="home_section6_image"
              src={require("../../../assets/images/pages/home/homeSec6Img4.png")}
              alt=""
            />
            <h5 className="home_section6_heading">100% Secure Payments</h5>
            <p className="home_section6_short_desc">
              Visa , Mastercard, Amex, Paypal & more
            </p>
          </div>
          {/* -------------------- copy ----------------------- */}
          {/* -------------------- copy ----------------------- */}
          <div className="home_section6_item">
            <img
              className="home_section6_image"
              src={require("../../../assets/images/pages/home/homeSec6Img5.png")}
              alt=""
            />
            <h5 className="home_section6_heading">Cash On Delivery</h5>
            <p className="home_section6_short_desc">
              We offer Cash on Delivery
            </p>
          </div>
          {/* -------------------- copy ----------------------- */}
        </div>
      </div>
    </div>
  );
};

export default HomeSection6;
