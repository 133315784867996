import {
  ABOUTUS,
  ADD_PROMO_CODE_IN_PACKAGE,
  BASIC_SETUP,
  BLOGS,
  CART,
  CATEGORIES,
  CITIES,
  CUSTOMER,
  CUSTOMER_PACKAGE_CART,
  FAQS,
  GALLERIES,
  LANGUAGES,
  ORDERS,
  PACKAGE_ADD_ONS,
  PACKAGES,
  PACKAGES_ORDERS,
  PANDITS,
  PRODUCTS,
  PROMO_CODES,
  PUJAS,
  SELECTED_CATEGORY,
  SET_CURRENT_CART,
  SET_FORM_ADDRESS,
  SET_FORM_DATE,
  SET_FORM_LANGUAGE,
  SET_FORM_PUJA,
  SET_FORM_PUJA_CATEGORY,
  SET_FORM_SPACKAGE,
  SET_PRODUCT_SELECTED_PROMOCODE,
  SET_SELECTED_CATEGORY,
  STATES,
  TESTIMONIALS,
  WISHLIST,
} from "./constants";

export function getProductsList() {
  return {
    type: PRODUCTS,
  };
}

export function getCategoriesList() {
  return {
    type: CATEGORIES,
  };
}
export function getCustomerData() {
  return {
    type: CUSTOMER,
  };
}

export function getWishlistData() {
  return {
    type: WISHLIST,
  };
}

export function getCartData() {
  return {
    type: CART,
  };
}

export function getOrdersData() {
  return {
    type: ORDERS,
  };
}
export function getPackagesOrdersData() {
  return {
    type: PACKAGES_ORDERS,
  };
}

export function getFaqsList() {
  return {
    type: FAQS,
  };
}

export function getBasicDetail() {
  return {
    type: BASIC_SETUP,
  };
}

export function getTestimonialsList() {
  return {
    type: TESTIMONIALS,
  };
}

export function getPanditList() {
  return {
    type: PANDITS,
  };
}
export function getCitiesList() {
  return {
    type: CITIES,
  };
}
export function getStatesList() {
  return {
    type: STATES,
  };
}
export function getLanguagesList() {
  return {
    type: LANGUAGES,
  };
}
export function getPujasList() {
  return {
    type: PUJAS,
  };
}
export function getSelectedCategory() {
  return {
    type: SELECTED_CATEGORY,
  };
}

export function setSelectedCategory(data) {
  return {
    type: SET_SELECTED_CATEGORY,
    data,
  };
}
export function getAllPackages() {
  return {
    type: PACKAGES,
  };
}
export function getAllBlogs() {
  return {
    type: BLOGS,
  };
}
export function getAllAboutUs() {
  return {
    type: ABOUTUS,
  };
}

export function getAllPackageAddOns() {
  return {
    type: PACKAGE_ADD_ONS,
  };
}

export function getAllCustomerPackageCart() {
  return {
    type: CUSTOMER_PACKAGE_CART,
  };
}

export function addPromoCodeInPackage(data) {
  return {
    type: ADD_PROMO_CODE_IN_PACKAGE,
    data,
  };
}

export function getAllGalleries() {
  return {
    type: GALLERIES,
  };
}

export function getAllPromoCodes() {
  return {
    type: PROMO_CODES,
  };
}
export function setProductSelectedPromoCode(data) {
  return {
    type: SET_PRODUCT_SELECTED_PROMOCODE,
    data,
  };
}

// ------- booking form constants --------
// export const SET_FORM_PUJA_CATEGORY = "pujaCategory";
// export const SET_FORM_DATE = "date";
// export const SET_FORM_ADDRESS = "address";
// export const SET_FORM_LANGUAGE = "language";
export function setFormPujaCategory(data) {
  return {
    type: SET_FORM_PUJA_CATEGORY,
    data,
  };
}
export function setFormDate(data) {
  return {
    type: SET_FORM_DATE,
    data,
  };
}
export function setFormAddress(data) {
  return {
    type: SET_FORM_ADDRESS,
    data,
  };
}
export function setFormLanguage(data) {
  return {
    type: SET_FORM_LANGUAGE,
    data,
  };
}
export function setFormSpackage(data) {
  return {
    type: SET_FORM_SPACKAGE,
    data,
  };
}
export function setFormPuja(data) {
  return {
    type: SET_FORM_PUJA,
    data,
  };
}

// ------- booking form constants --------

export function setCurrentCart(data) {
  return {
    type: SET_CURRENT_CART,
    data,
  };
}
