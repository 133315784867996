import React from "react";
import { usePanditHook } from "../../../redux/hooks/usePanditHook";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import Banner from "../../components/Banner";
import "../../../assets/css/pages/pandit.css";
import { Link } from "react-router-dom";
import RenderPandits from "./cmps/RenderPandits";
import useScrollToTop from "../../../hooks/UseScrollToTop";

const Pandit = () => {
  const { allPandits } = usePanditHook();
  useScrollToTop();
  return (
    <div>
      <Header />
      <Banner title="Our Pandit" showItemSlider={false} />
      <div className="container   my-3">
        <h5 className="text-primary  wishlistPage_heading">
          <Link to="/" className="text-decoration-none">
            <u>Home</u>
          </Link>
          <span> | </span>
          <span>Pandit</span>
        </h5>
      </div>
      <div className="container my-5">
        {/* ---------------------------------------- */}
        <div className="panditCmp1Box row">
          {allPandits.map((value, index) => (
            <RenderPandits key={index} value={value} />
          ))}
        </div>
        {/* ---------------------------------------- */}
      </div>
      <Footer />
    </div>
  );
};

export default Pandit;
