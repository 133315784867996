import React from "react";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import { Link } from "react-router-dom";
import { useFaqs } from "../../../redux/hooks/FaqHook";
import Banner from "../../components/Banner";
import EditedTextCmp from "../../components/EditedTextCmp";

const RenderFaqs = ({ value, index }) => (
  <div className="accordion-item">
    <h2 className="accordion-header" id={`heading_faq_${index}`}>
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#collapse_faq_${index}`}
        aria-expanded="true"
        aria-controls={`collapse_faq_${index}`}
      >
        <b>{value.question}</b>
      </button>
    </h2>
    <div
      id={`collapse_faq_${index}`}
      className="accordion-collapse collapse"
      aria-labelledby={`heading_faq_${index}`}
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <EditedTextCmp givenState={value.answer} />
      </div>
    </div>
  </div>
);

const Faq = () => {
  const { allFaqs } = useFaqs();
  return (
    <div>
      <Header />
      <Banner title="Faq's" showItemSlider={false} />
      <div className="container mt-5">
        <div className="container my-3">
          <h5 className="text-primary  wishlistPage_heading">
            <Link to="/" className="text-decoration-none">
              <u>Home</u>
            </Link>
            <span> | </span>
            <span>faq</span>
          </h5>
        </div>
      </div>
      {/* -------------------------- */}
      <div className="container">
        <div className="my-5">
          {/* =========================================================== */}
          <div className="accordion" id="accordionExample">
            {allFaqs.map((value, index) => (
              <RenderFaqs key={index} value={value} index={index} />
            ))}
          </div>
          {/* =========================================================== */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Faq;
