import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useStateHook } from "../../../../redux/hooks/useStateHook";
import { useCityHook } from "../../../../redux/hooks/useCityHook";

function DateModal({
  visibleDateModal,
  setvisibleDateModal,
  data,
  bookNowFunction,
}) {
  const handleClose = () => setvisibleDateModal(false);
  const { allStates } = useStateHook();
  const { allCities } = useCityHook();

  const handleSave = (e) => {
    e.preventDefault();

    bookNowFunction();
  };

  return (
    <>
      <Modal show={visibleDateModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Booking Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSave}>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Name
              </label>
              <input
                type="text"
                required
                value={data.userName}
                onChange={(e) => {
                  data.setuserName(e.target.value);
                }}
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Mobile Number
              </label>
              <input
                type="text"
                required
                value={data.anotherMobile}
                onChange={(e) => {
                  data.setanotherMobile(e.target.value);
                }}
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Select State
              </label>
              <select
                className="form-select"
                value={data.userState}
                onChange={(e) => {
                  data.setUserState(e.target.value);
                }}
                required
                aria-label="Default select example"
              >
                <option value="">Select State</option>
                {allStates.map((value, index) => {
                  return (
                    <option key={index} value={value.name}>
                      {value.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Select City
              </label>
              <select
                className="form-select"
                value={data.city}
                onChange={(e) => {
                  data.setCities(e.target.value);
                }}
                required
                aria-label="Default select City"
              >
                <option value="">Select City</option>
                {allCities
                  .filter((value) => value.state === data.userState)
                  .map((value, index) => {
                    return (
                      <option key={index} value={value.name}>
                        {value.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <Modal.Footer>
              <Button variant="secondary" type="button" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Book Now
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DateModal;
