import React from "react";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import Banner from "../../components/Banner";
import { Link } from "react-router-dom";
import { useAboutUsHook } from "../../../redux/hooks/useAboutUsHook";
import "../../../assets/css/pages/aboutus.css";
import EditedTextCmp from "../../components/EditedTextCmp";

const Render1 = ({ value }) => {
  return (
    <div className="about_us_box my-5">
      <div className="row">
        <div className="col-md-5 imgBox my-2">
          <img src={value.image} alt="" />
        </div>
        <div className="col-md-7 my-2">
          <h3>{value.title}</h3>
          <h5>
            <EditedTextCmp givenState={value.shortDesc} />
          </h5>
          <p>
            <EditedTextCmp givenState={value.description} />
          </p>
        </div>
      </div>
    </div>
  );
};
const Render2 = ({ value }) => {
  return (
    <div className="about_us_box my-5">
      <div className="row">
        <div className="col-md-7 my-2">
          <h3>{value.title}</h3>
          <h5>
            <EditedTextCmp givenState={value.shortDesc} />
          </h5>
          <p>
            <EditedTextCmp givenState={value.description} />
          </p>
        </div>
        <div className="col-md-5 imgBox my-2">
          <img src={value.image} alt="" />
        </div>
      </div>
    </div>
  );
};

const About = () => {
  const { allAboutUs } = useAboutUsHook();
  return (
    <div>
      <Header />
      <Banner title="About Us" />
      <div className="container mt-5">
        <div className="container my-3">
          <h5 className="text-primary  wishlistPage_heading">
            <Link to="/" className="text-decoration-none">
              <u>Home</u>
            </Link>
            <span> | </span>
            <span>About Us</span>
          </h5>
        </div>
      </div>
      {/* -------------------------- */}
      <div className="container">
        <div className="my-5">
          {/* =========================================================== */}
          {allAboutUs.map((value, index) => {
            if ((index + 1) % 2 === 0) {
              return <Render1 key={index} value={value} />;
            } else {
              return <Render2 key={index} value={value} />;
            }
          })}
          {/* =========================================================== */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
