// ------- products --------
export const PRODUCTS = "products";
export const SET_PRODUCTS = "set_products";
// ------- products --------

// ------- categories --------
export const CATEGORIES = "categories";
export const SET_CATEGORIES = "set_categories";
// ------- categories --------

// ------- categories --------
export const CUSTOMER = "customer";
export const SET_CUSTOMER = "set_customer";
// ------- categories --------

// ------- categories --------
export const WISHLIST = "wishlist";
export const SET_WISHLIST = "set_wishlist";
// ------- categories --------

// ------- categories --------
export const CART = "cart";
export const SET_CART = "set_cart";
// ------- categories --------

// ------- orders --------
export const ORDERS = "orders";
export const SET_ORDERS = "set_orders";
// ------- orders --------

// ------- orders --------
export const PACKAGES_ORDERS = "packages_orders";
export const SET_PACKAGES_ORDERS = "set_packages_orders";
// ------- orders --------

// ------- categories --------
export const FAQS = "faqs";
export const SET_FAQS = "set_faqs";
// ------- categories --------

// ------- categories --------
export const TESTIMONIALS = "testimonials";
export const SET_TESTIMONIALS = "set_testimonials";
// ------- categories --------

// ------- products --------
export const BASIC_SETUP = "basic_setup";
export const SET_BASIC_SETUP = "set_basic_setup";
// ------- products --------

// ------- pandits --------
export const PANDITS = "pandits";
export const SET_PANDITS = "set_pandits";
// ------- pandits --------

// ------- states --------
export const STATES = "states";
export const SET_STATES = "set_states";
// ------- pandits --------

// ------- cities --------
export const CITIES = "cities";
export const SET_CITIES = "set_cities";
// ------- pandits --------

// ------- languages --------
export const LANGUAGES = "languages";
export const SET_LANGUAGES = "set_languages";
// ------- pandits --------

// ------- pujas --------
export const PUJAS = "pujas";
export const SET_PUJAS = "set_pujas";
// ------- pandits --------

// ------- pujas --------
export const SELECTED_CATEGORY = "selected_category";
export const SET_SELECTED_CATEGORY = "set_selected_category";
// ------- pandits --------

// ------- pujas --------
export const PACKAGES = "packages";
export const SET_PACKAGES = "set_packages";
// ------- pandits --------

// ------- pujas --------
export const BLOGS = "blogs";
export const SET_BLOGS = "set_blogs";
// ------- pandits --------

// ------- pujas --------
export const ABOUTUS = "aboutus";
export const SET_ABOUTUS = "set_aboutus";
// ------- pandits --------

// ------- pujas --------
export const PACKAGE_ADD_ONS = "package_add_ons";
export const SET_PACKAGE_ADD_ONS = "set_package_add_ons";
// ------- pandits --------

// ------- pujas --------
export const CUSTOMER_PACKAGE_CART = "customer_package_cart";
export const SET_CUSTOMER_PACKAGE_CART = "set_customer_package_cart";
export const ADD_PROMO_CODE_IN_PACKAGE = "add_promo_code_in_package";
// ------- pandits --------

// ------- pujas --------
export const GALLERIES = "galleries";
export const SET_GALLERIES = "set_galleries";
// ------- pandits --------

// ------- pujas --------
export const PROMO_CODES = "promo_codes";
export const SET_PROMO_CODES = "set_promo_codes";
// ------- pandits --------

// ------- pujas --------
export const SET_PRODUCT_SELECTED_PROMOCODE = "set_product_selected_promo_code";
// ------- pandits --------

// ------- booking form constants --------
export const SET_FORM_PUJA_CATEGORY = "pujaCategory";
export const SET_FORM_DATE = "date";
export const SET_FORM_ADDRESS = "address";
export const SET_FORM_LANGUAGE = "language";
export const SET_FORM_SPACKAGE = "spackage";
export const SET_FORM_PUJA = "puja";
// ------- booking form constants --------

export const SET_CURRENT_CART = "currentCart";
