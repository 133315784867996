// ------------   redux ---------------------
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllAboutUs } from "../action";
// ------------   redux ---------------------
export function useAboutUsHook() {
  const dispatch = useDispatch();
  const allAboutUs = useSelector((state) => state.aboutusReducer);

  const updateAboutUs = () => {
    dispatch(getAllAboutUs());
  };

  useEffect(() => {
    if (allAboutUs.length < 1) {
      dispatch(getAllAboutUs());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { allAboutUs, updateAboutUs };
}
