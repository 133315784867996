import {
  ADD_PROMO_CODE_IN_PACKAGE,
  SET_CUSTOMER_PACKAGE_CART,
} from "../constants";

const allcustomerPackageCart = [];
export const customerPackageCartReducer = (
  state = allcustomerPackageCart,
  action
) => {
  switch (action.type) {
    case SET_CUSTOMER_PACKAGE_CART:
      return action.data.data;
    case ADD_PROMO_CODE_IN_PACKAGE:
      const prevData = state;

      const packageId = action.data.packageId;
      const promoCode = action.data.givenPromoCode;

      const newStateArray = prevData.map((v) => {
        if (v.package._id === packageId) {
          const newData = v;
          newData.promoCode = promoCode;
          return newData;
        } else {
          return v;
        }
      });

      console.log("====================================");
      console.log(newStateArray);
      console.log("====================================");

      return newStateArray;
    default:
      return state;
  }
};
