import React, { useEffect, useRef, useState } from "react";
import "./OnboardingForm.css";
import TextEditorCmp from "../../components/TextEditorCmp";
import { Form } from "react-bootstrap";
import { getDateTimeInStringFormat } from "../../../helpers/date&time_functions/dateTimeFunctions";
import { convertToRaw } from "draft-js";

const termsAndConditionArray = [
  {
    title: "Cancellation Policy",
    description: `Once an associate Pandit or Purohit is scheduled for performing any Puja or Rituals at Yajamana's place at a particular date and time, they cannot cancel the appointment unless under exceptional circumstances.`,
  },
  {
    title: "Direct Contact Prohibition",
    description: `No associate Pandit or Purohit may contact any of Pujanya's Yajamana directly or indirectly. Any violation of this rule will result in strict action against the associate Pandit or Purohit.`,
  },
  {
    title: "Commission on Dakshina",
    description: `A 20% commission will be charged on the amount of Dakshina received by the associate Pandit or Purohit at Yajamana's place, provided by Pujanya. If the same associate Pandit or Purohit goes to the same Yajamana for performing any Rituals or Pujas independently, a 15% commission will be charged on the amount of Dakshina received, whether they contacted the Yajamana directly or indirectly.`,
  },
  {
    title: "Transparency",
    description: `The associate Pandit or Purohit must be transparent with the amount of Dakshina received at the Yajamana's place.`,
  },
  {
    title: "Samagri Charges",
    description: `If the associate Pandit or Purohit provides the Puja Samagri from their own resources and the value exceeds ₹5000, ₹50 will be given to them as a special commission. Further, if the order value exceeds ₹1000, ₹100 will be given as a referral commission.`,
  },
  {
    title: "Modification of Terms",
    description: `Pujanya reserves the right to modify these terms and conditions at any time.`,
  },
  {
    title: "Acceptance of Terms",
    description: `By using this service, you accept and agree to be bound by the terms and provisions of this agreement. In addition, when using these particular services, you shall be subject to any posted guidelines or rules applicable to such services. Any participation in this service will constitute acceptance of this agreement. If you do not agree to abide by the above, please do not use this service.`,
  },
  {
    title: "Payouts and Incentives",
    description: `Detailed information about dakshina payouts and the incentives structure, including customer ratings-based incentives, special commissions for providing Puja Samagri, and referral bonuses, will be provided in a separate PDF document. This document will be sent to you via email or WhatsApp. Please review this document carefully for a comprehensive understanding of the payouts and incentives you are entitled to.`,
  },
];

export const OnboardingForm = () => {
  const img1Ref = useRef();
  const img2Ref = useRef();
  // ===================== ----------- redux states =================
  const allStatesData = [{ name: "state 1" }];
  const allCitiesData = [{ state: "state 1", name: "city 1" }];
  const allPujasData = [{ name: "puja 1" }];
  const allPanditEducationsData = [{ name: "Education 1" }];
  // ===================== ----------- redux states =================
  // const [image, setimage] = useState("");
  const [name, setname] = useState("");
  const [dob, setdob] = useState("");

  // =============== Personal Information =============================
  const [gender, setgender] = useState("Male");
  const [addressCity, setaddressCity] = useState("");
  const [addressState, setaddressState] = useState("");
  const [address, setaddress] = useState("");
  const [emailId, setemailId] = useState("");
  const [mobileNumber, setmobileNumber] = useState("");

  // =============== Professional Information =============================
  const [languages, setlanguages] = useState("");
  const [experience, setexperience] = useState("");
  const [specialization, setspecialization] = useState("");
  // const [pujas, setpujas] = useState("");
  // const [totalPujas, settotalPujas] = useState("");
  //-----------
  const [jobType, setjobType] = useState("Part-Time"); // [parttime/fulltime]
  // const [prefferedDays, setprefferedDays] = useState(""); //-------------
  const [preferredStartTimes, setpreferredStartTimes] = useState("");
  const [preferredEndsTimes, setpreferredEndsTimes] = useState("");
  // const [cities, setcities] = useState(""); //-------------

  // ============== Education and Training:
  const [education, seteducation] = useState("");
  //-------------
  const [trainingInstitute, settrainingInstitute] = useState("");
  const [certifications, setcertifications] = useState("");
  // ==============  references ------------------------------
  // reference1
  const [reference1Name, setreference1Name] = useState("");
  const [reference1Number, setreference1Number] = useState("");
  const [reference1Relation, setreference1Relation] = useState("");
  // reference2
  const [reference2Name, setreference2Name] = useState("");
  const [reference2Number, setreference2Number] = useState("");
  const [reference2Relation, setreference2Relation] = useState("");
  // ----------- references ------------------------------
  // ============= other
  const [description, setdescription] = useState("");
  // ================== other --------------------
  const [haveItems, sethaveItems] = useState("yes"); // ['yes', 'no']
  const [joiningReason, setjoiningReason] = useState("");
  const [additionalSkills, setadditionalSkills] = useState("");
  // ------------- new --------------------------------

  //--------- signature ----------------
  // const [signature, setsignature] = useState(""); // image format /----------------
  const [date, setdate] = useState();
  const [isTermsAccepted, setisTermsAccepted] = useState(false);
  // ------------- new --------------------------------

  function handleFormSubmit(e) {
    e.preventDefault();

    if (!isTermsAccepted) {
      return alert("Please confirm and accept the terms and conditions!");
    }

    let form_data = new FormData();
    form_data.append("image", img2Ref.current.files[0]);
    form_data.append("signature", img1Ref.current.files[0]);
    form_data.append("name", name);
    form_data.append("dob", dob);
    form_data.append("gender", gender);
    form_data.append("addressCity", addressCity);
    form_data.append("addressState", addressState);
    form_data.append("address", address);
    form_data.append("emailId", emailId);
    form_data.append("mobileNumber", mobileNumber);
    form_data.append("languages", languages);
    form_data.append("experience", experience);
    form_data.append("specialization", specialization);
    form_data.append("jobType", jobType);
    form_data.append("preferredStartTimes", preferredStartTimes);
    form_data.append("preferredEndsTimes", preferredEndsTimes);
    form_data.append("education", education);
    form_data.append("trainingInstitute", trainingInstitute);
    form_data.append("certifications", certifications);
    form_data.append("reference1Name", reference1Name);
    form_data.append("reference1Number", reference1Number);
    form_data.append("reference1Relation", reference1Relation);
    form_data.append("reference2Name", reference2Name);
    form_data.append("reference2Number", reference2Number);
    form_data.append("reference2Relation", reference2Relation);
    form_data.append("haveItems", haveItems);
    form_data.append("joiningReason", joiningReason);
    form_data.append("additionalSkills", additionalSkills);
    form_data.append("date", date);
    form_data.append("isTermsAccepted", isTermsAccepted);

    try {
      if (typeof description !== "string") {
        const contentState = description.getCurrentContent();
        const contentStateJSON = JSON.stringify(convertToRaw(contentState));
        form_data.append("description", contentStateJSON);
      }
    } catch (error) {}

    let url = "";
    let method = "POST";
    url = `${process.env.REACT_APP_BACKEND_1}/guest/pandits/add`;
    fetch(url, {
      method: method,
      body: form_data,
    })
      .then((v) => v.json())
      .then((v) => {
        if (v.message === "success") {
          alert("Onboarding Request Sent successfully!");
        } else {
          if (v.detail) {
            alert(v.detail);
          } else {
            alert("Something Went Wrong!");
          }
        }
      })
      .catch((err) => {
        console.log("====================================");
        console.log(err.message);
        console.log("====================================");
        alert("Something Went Wrong!");
      });
  }

  useEffect(() => {
    setdate(getDateTimeInStringFormat());
  }, []);

  return (
    <div>
      <div className="container">
        <div className="OnboardingForm my-3 p-3">
          <form onSubmit={handleFormSubmit}>
            <div className="row">
              {/* -------------------  */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Image</label>
                  <input
                    type="file"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    ref={img2Ref}
                  />
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={name}
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">dob</label>
                  <input
                    type="date"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={dob}
                    onChange={(e) => {
                      setdob(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Gender</label>
                  <select
                    className="form-select"
                    value={gender}
                    onChange={(e) => {
                      setgender(e.target.value);
                    }}
                    required
                    aria-label="Default select example"
                  >
                    {["Male", "Female", "Other"].map((value, index) => {
                      return (
                        <option key={index} value={value}>
                          {value}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">State</label>
                  <select
                    className="form-select"
                    value={addressState}
                    onChange={(e) => {
                      setaddressState(e.target.value);
                    }}
                    required
                    aria-label="Default select example"
                  >
                    <option value="">Select State</option>
                    {allStatesData.map((value, index) => {
                      return (
                        <option key={index} value={value.name}>
                          {value.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">City</label>
                  <select
                    className="form-select"
                    value={addressCity}
                    onChange={(e) => {
                      setaddressCity(e.target.value);
                    }}
                    required
                    aria-label="Default select City"
                  >
                    <option value="">Select City</option>
                    {allCitiesData
                      .filter((value) => value.state === addressState)
                      .map((value, index) => {
                        return (
                          <option key={index} value={value.name}>
                            {value.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              {/* -------------------  */}

              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Address</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={address}
                    onChange={(e) => {
                      setaddress(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Email Id</label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={emailId}
                    onChange={(e) => {
                      setemailId(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Mobile Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={mobileNumber}
                    onChange={(e) => {
                      setmobileNumber(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Language</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={languages}
                    onChange={(e) => {
                      setlanguages(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Years Of Experience</label>
                  <input
                    type="number"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={experience}
                    onChange={(e) => {
                      setexperience(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Specializations</label>
                  <select
                    className="form-select"
                    value={specialization}
                    onChange={(e) => {
                      setspecialization(e.target.value);
                    }}
                    required
                    aria-label="Default select Puja"
                  >
                    <option value="">Select</option>
                    {allPujasData.map((value, index) => {
                      return (
                        <option key={index} value={value.name}>
                          {value.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Job Type</label>
                  <select
                    className="form-select"
                    value={jobType}
                    onChange={(e) => {
                      setjobType(e.target.value);
                    }}
                    required
                    aria-label="Default select example"
                  >
                    {["Part-Time", "Full-Time"].map((value, index) => {
                      return (
                        <option key={index} value={value}>
                          {value}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Daily Work Start Time</label>
                  <input
                    type="time"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={preferredStartTimes}
                    onChange={(e) => {
                      setpreferredStartTimes(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Daily Work End Time</label>
                  <input
                    type="time"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={preferredEndsTimes}
                    onChange={(e) => {
                      setpreferredEndsTimes(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* -------------------  */}

              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">
                    Qualification / Education
                  </label>
                  <select
                    className="form-select"
                    value={education}
                    onChange={(e) => {
                      seteducation(e.target.value);
                    }}
                    required
                    aria-label="Default select example"
                  >
                    <option value="">Select</option>
                    {allPanditEducationsData.map((value, index) => {
                      return (
                        <option key={index} value={value.name}>
                          {value.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Institute</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={trainingInstitute}
                    onChange={(e) => {
                      settrainingInstitute(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">
                    Qualification Certificate Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={certifications}
                    onChange={(e) => {
                      setcertifications(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* -------------------  */}
              {/* ========================================== reference  */}

              <div className="col-md-12">
                <div className="mb-3">
                  <button
                    type="button"
                    onClick={() => {}}
                    className="btn btn-primary btnT1"
                  >
                    + Reference 1
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Reference 1 Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={reference1Name}
                    onChange={(e) => {
                      setreference1Name(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Reference 1 Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={reference1Number}
                    onChange={(e) => {
                      setreference1Number(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Reference 1 Relation</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={reference1Relation}
                    onChange={(e) => {
                      setreference1Relation(e.target.value);
                    }}
                  />
                </div>
              </div>

              {/* ========================================== */}
              <div className="col-md-12">
                <div className="mb-3">
                  <button
                    type="button"
                    onClick={() => {}}
                    className="btn btn-primary btnT1"
                  >
                    + Reference 2
                  </button>
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Reference 2 Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={reference2Name}
                    onChange={(e) => {
                      setreference2Name(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Reference 2 Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={reference2Number}
                    onChange={(e) => {
                      setreference2Number(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Reference 2 Relation</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={reference2Relation}
                    onChange={(e) => {
                      setreference2Relation(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* -------------------  */}
              {/* ========================================== */}
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">
                    Describe yourself in detail
                  </label>
                  <div className="border">
                    <TextEditorCmp
                      givenState={description}
                      givenStateSetter={setdescription}
                    />
                  </div>
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">
                    Do you own necessary puja items and attire?
                  </label>
                  <select
                    className="form-select"
                    value={haveItems}
                    onChange={(e) => {
                      sethaveItems(e.target.value);
                    }}
                    required
                    aria-label="Default select example"
                  >
                    {["yes", "no"].map((value, index) => {
                      return (
                        <option key={index} value={value}>
                          {value}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Joining Reason</label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={joiningReason}
                    onChange={(e) => {
                      setjoiningReason(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* -------------------  */}
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Additional Skills</label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={additionalSkills}
                    onChange={(e) => {
                      setadditionalSkills(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* -------------------  */}
              {/* ------------------------------ */}
              <div>
                <div>
                  <h5>Terms & Condition </h5>
                  <div className="mx-2">
                    {termsAndConditionArray.map((term, i) => {
                      return (
                        <div key={i}>
                          <div>
                            <h6>
                              {i + 1}. {term.title} :
                            </h6>
                            <p>{term.description}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <Form.Check
                    inline
                    label="confirm and accept terms and condition"
                    name="group1"
                    type="checkbox"
                    id={`inline-1`}
                    onChange={(e) => {
                      setisTermsAccepted(!isTermsAccepted);
                    }}
                  />
                </div>
              </div>
              {/* ------------------------------ */}
              {/* -------------------  */}

              <div className="mt-3 mb-4">
                <h5>Declaration</h5>
                <p>
                  I hereby declare that all the information provided above is
                  true and correct to the best of my knowledge and belief.
                </p>
              </div>

              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Signature</label>
                  <input
                    type="file"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    ref={img1Ref}
                  />
                </div>
              </div>

              {/* -------------------  */}
            </div>
            <button type="submit" className="btn btn-primary btnT1">
              Submit
            </button>
          </form>
        </div>
      </div>
      {/* 
      <div className="col-md-6">
<div className="mb-3">
  <label  className="form-label">
    Specializations
  </label>
  <select
                  className="form-select"
                  value={data.haveItems}
                  onChange={(e) => {
                    data.sethaveItems(e.target.value)
                  }}
                  required
                  aria-label="Default select example"
                >
                  {['yes', 'no'].map((value, index) => {
                    return (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    )
                  })}
                </select>
</div>
</div>
 */}
    </div>
  );
};
