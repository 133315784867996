import Cookies from "js-cookie";
import { encryptToken } from "../../../../../helpers/EncryptionHandler";

const NewSignup = async (mobile, successCallback, errorCallback) => {
  const form_data = new FormData();

  form_data.append("mobile", mobile);

  fetch(
    `${process.env.REACT_APP_BACKEND_1}/auth/customer/signin-new-customer`,
    {
      method: "POST",
      body: form_data,
    }
  )
    .then((v) => v.json())
    .then((v) => {
      if (v.message !== "success") {
        if (v.details) {
          errorCallback(v.details);
        } else {
          errorCallback("something went wrong! Please try again.");
        }
        return null;
      }
      if (!v.token) {
        return errorCallback("Token error! Please try again.");
      }

      const encryptedToken = encryptToken(v.token);
      Cookies.set("customerToken", encryptedToken);
      successCallback();
    })
    .catch((err) => {
      errorCallback(err.message);
    });
};

export default NewSignup;
