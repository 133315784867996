export const GetCurrentLatLon = (callBack,callBack2) => {
  if ("geolocation" in navigator) {
    // Get current position
    navigator.geolocation.getCurrentPosition(
      function (position) {
        // Retrieve latitude and longitude
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        // Do something with the latitude and longitude
        callBack(latitude, longitude,callBack2);
      },
      function (error) {
        // Handle errors
        console.error("Error getting geolocation:", error.message);
      }
    );
  } else {
    // Geolocation is not supported
    console.error("Geolocation is not supported by this browser.");
  }
};
