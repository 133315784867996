import { SET_TESTIMONIALS } from "../constants";

const allTestimonials = [];
export const testimonialsReducer = (state = allTestimonials, action) => {
  switch (action.type) {
    case SET_TESTIMONIALS:
      return action.data.data;
    default:
      return state;
  }
};
