import { SET_PROMO_CODES } from "../constants";

const promoCodeData = [];
export const promoCodeReducer = (state = promoCodeData, action) => {
  switch (action.type) {
    case SET_PROMO_CODES:
      return action.data.data;
    default:
      return state;
  }
};
