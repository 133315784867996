import { SET_WISHLIST } from "../constants";

const allWishlists = [];
export const wishlistReducer = (state = allWishlists, action) => {
  switch (action.type) {
    case SET_WISHLIST:
      return action.data.data;
    default:
      return state;
  }
};
