import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function LocationModal({
  visibleLocationModal,
  setvisibleLocationModal,
  data,
}) {
  const handleClose = () => setvisibleLocationModal(false);

  const handleSave = (e) => {
    e.preventDefault();

    data.setvisibleLocationModal(false);
    data.setvisibleDateModal(true);
  };

  return (
    <>
      <Modal show={visibleLocationModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Location Specific Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* ================================================== */}
          <form onSubmit={handleSave}>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Enter Your Area name
              </label>
              <textarea
                type="text"
                required
                value={data.address}
                onChange={(e) => {
                  data.setAddress(e.target.value);
                }}
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>

            <Modal.Footer>
              <Button variant="secondary" type="button" onClick={handleClose}>
                Next
              </Button>
            </Modal.Footer>
          </form>
          {/* ================================================== */}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LocationModal;
