import { SET_FORM_ADDRESS } from "../constants";

const formAddressData = "";
export const formAddressReducer = (state = formAddressData, action) => {
  switch (action.type) {
    case SET_FORM_ADDRESS:
      return action.data;
    default:
      return state;
  }
};
