import { SET_FORM_PUJA } from "../constants";

const formPujaData = "";
export const formPujaReducer = (state = formPujaData, action) => {
  switch (action.type) {
    case SET_FORM_PUJA:
      return action.data;
    default:
      return state;
  }
};
