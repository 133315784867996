import React, { useEffect } from "react";
import ProductCmp1 from "./ProductCmp1";
import "../../../assets/css/pages/product.css";

// ------------   redux ---------------------
import { getProductsList, getCategoriesList } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
// ------------   redux ---------------------

import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import { useWishlist } from "./helperFunctions/WishlistFunctions";
import { Link, useNavigate } from "react-router-dom";
import Banner from "../../components/Banner";
import useScrollToTop from "../../../hooks/UseScrollToTop"; 

const Wishlist = () => {
  const dispatch = useDispatch();
  const { wishlist } = useWishlist(); 
  const AllProducts = useSelector((state) => state.productsReducer);
  const AllCategories = useSelector((state) => state.categoriesReducer);
  let wishlistProducts = AllProducts.filter((value) => {
    return wishlist.includes(value._id);
  });
  const navigate = useNavigate();

  useScrollToTop();

  useEffect(() => {
    if (!sessionStorage.getItem('accessToken')) {
      alert("Please Login...")
      navigate('/login');
    }
    if (AllProducts.length === 0) {
      dispatch(getProductsList());
    }
    if (AllCategories.length === 0) {
      dispatch(getCategoriesList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Header />
      <Banner title="Wishlists" showItemSlider={false} />
      <div className="container   my-3">
        <h5 className="text-primary  wishlistPage_heading">
          <Link to="/" className="text-decoration-none">
            <u>Home</u>
          </Link>
          <span> | </span>
          <span>Wishlists</span>
        </h5>
      </div>
      <div style={{ minHeight: "60vh" }}>
        <ProductCmp1
          AllProducts={wishlistProducts}
          AllCategories={AllCategories}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Wishlist;
