import { SET_PRODUCT_SELECTED_PROMOCODE } from "../constants";

const productSelectedPromoCodeData = "";
export const productSelectedPromoCodeReducer = (
  state = productSelectedPromoCodeData,
  action
) => {
  switch (action.type) {
    case SET_PRODUCT_SELECTED_PROMOCODE:
      return action.data;
    default:
      return state;
  }
};
