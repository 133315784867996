// ------------   redux ---------------------
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLanguagesList } from "../action";
// ------------   redux ---------------------
export function useLanguageHook() {
  const dispatch = useDispatch();
  const allLanguages = useSelector((state) => state.languagesReducer);

  const updateLanguagesData = () => {
    dispatch(getLanguagesList());
  };

  useEffect(() => {
    if (allLanguages.length < 1) {
      dispatch(getLanguagesList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { allLanguages, updateLanguagesData };
}
