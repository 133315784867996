import { useEffect } from "react";
import { Rating } from "react-simple-star-rating";

const PanditRatingSingleView = ({ givenValue, givenColor }) => {
  return (
    <div className="pb-3">
      <div className="rating_box p-2 px-3">
        <div className="pb-2 d-flex  ">
          <span
            className="rating_customer_icon me-2"
            style={{ backgroundColor: givenColor }}
          >
            {givenValue.customerName ? givenValue.customerName.charAt(0) : "V"}
          </span>
          <Rating initialValue={givenValue.ratingValue} size={25} />
        </div>
        <div className="p-2 ps-4">
          <p className="rating_text">{givenValue.review}</p>
        </div>
      </div>
    </div>
  );
};

export const PanditRatingsView = ({ reviewData }) => {
  const randomColor = [
    "rgb(241,104,69)",
    "rgb(243,44,147)",
    "rgb(214,7,36)",
    "rgb(227,56,90)",
    "rgb(174,79,116)",
  ];

  useEffect(() => {}, [reviewData]);

  return (
    <div>
      {reviewData.map((value, index) => {
        return (
          <PanditRatingSingleView
            key={index}
            givenValue={value}
            givenColor={randomColor[index / randomColor.length]}
          />
        );
      })}
    </div>
  );
};
