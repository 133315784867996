import "../../../assets/css/pages/myorders.css";
import React, { useEffect } from "react";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import { Link } from "react-router-dom";
import { useOrders } from "../../../hooks/OrderHook";
import useScrollToTop from "../../../hooks/UseScrollToTop";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentCart } from "../../../redux/action";
import { usePackageOrders } from "../../../hooks/PackageOrderHook";

const Order = () => {
  const { allOrders } = useOrders();
  const { allPackageOrders } = usePackageOrders();

  useScrollToTop();
  const dispatch = useDispatch();

  const currentCart = useSelector((state) => state.currentCartReducer);
  const setcurrentCart = (givenCart) => {
    dispatch(setCurrentCart(givenCart));
  };

  useEffect(() => {
    if (currentCart === "") {
      dispatch(setCurrentCart("products"));
    }
  }, [currentCart, dispatch]);

  return (
    <div>
      <Header />
      <div className="container mt-5">
        <div className="container   my-3">
          <h5 className="text-primary  wishlistPage_heading">
            <Link to="/" className="text-decoration-none">
              <u>Home</u>
            </Link>
            <span> | </span>
            <span>My Orders</span>
          </h5>
        </div>
      </div>
      {/* -------------------------- */}
      <div className="container mb-5">
        <div className="container">
          <div>
            <span className="me-3">
              <button
                className={`btn ${
                  currentCart === "products" ? "btn-primary" : "btn-secondary"
                } `}
                onClick={() => {
                  setcurrentCart("products");
                }}
              >
                Product Cart
              </button>
            </span>
            <span>
              <button
                className={`btn ${
                  currentCart === "packages" ? "btn-primary" : "btn-secondary"
                } `}
                onClick={() => {
                  setcurrentCart("packages");
                }}
              >
                Package Cart
              </button>
            </span>
          </div>
        </div>
        {currentCart === "products" && (
          <div className="row">
            {allOrders.map((value, index) => (
              <div className="col-md-6" key={index}>
                <div className="myorderbox">
                  <div>
                    <span className="myorderbox-title"> Order Id : </span>
                    <span className="myorderbox-value">{value._id}</span>
                  </div>
                  <div>
                    <span className="myorderbox-title">Total Products : </span>
                    <span className="myorderbox-value">
                      {value.cartProductsArray.length}
                    </span>
                  </div>
                  <div>
                    <span className="myorderbox-title">Order Value : </span>
                    <span className="myorderbox-value">
                      {value.orderTotal} Rs
                    </span>
                  </div>
                  <div>
                    <span className="myorderbox-title">Date : </span>
                    <span className="myorderbox-value">{value.date}</span>
                  </div>
                  <div>
                    <span className="myorderbox-title">Time : </span>
                    <span className="myorderbox-value">{value.time}</span>
                  </div>
                  <div>
                    <span className="myorderbox-title">Order Status : </span>
                    <span className="myorderbox-value">{value.status}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {currentCart === "packages" && (
          <div className="row">
            {allPackageOrders.map((value, index) => {
              return (
                <div className="col-md-6" key={index}>
                  <div className="myorderbox">
                    <div>
                      <span className="myorderbox-title">Booking Id :</span>
                      <span className="myorderbox-value"> {value._id}</span>
                    </div>
                    <div>
                      <span className="myorderbox-title">Puja : </span>
                      <span className="myorderbox-value">
                        {value.packageInfo.map((v, i) => (
                          <span>{v.package.title}, &nbsp;</span>
                        ))}
                      </span>
                    </div>
                    <div>
                      <span className="myorderbox-title">Booking Value : </span>
                      <span className="myorderbox-value">
                        {value.orderTotal} Rs
                      </span>
                    </div>
                    <div>
                      <span className="myorderbox-title">Booking Date : </span>
                      <span className="myorderbox-value">
                        {value.requestDate}
                      </span>
                    </div>
                    <div>
                      <span className="myorderbox-title">Booking Time : </span>
                      <span className="myorderbox-value">
                        {value.requestTime}
                      </span>
                    </div>
                    <div>
                      <span className="myorderbox-title">Order Status : </span>
                      <span className="myorderbox-value">{value.status}</span>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* {allOrders.map((value, index) => (
              <div className="col-md-4" key={index}>
                <div className="myorderbox">
                
                  <div>
                    <span className="myorderbox-title">Total Products : </span>
                    <span className="myorderbox-value">
                      {value.cartProductsArray.length}
                    </span>
                  </div>
                  <div>
                    <span className="myorderbox-title">Order Value : </span>
                    <span className="myorderbox-value">
                      {value.orderTotal} Rs
                    </span>
                  </div>
                  <div>
                    <span className="myorderbox-title">Date : </span>
                    <span className="myorderbox-value">{value.date}</span>
                  </div>
                  <div>
                    <span className="myorderbox-title">Time : </span>
                    <span className="myorderbox-value">{value.time}</span>
                  </div>
                  <div>
                    <span className="myorderbox-title">Order Status : </span>
                    <span className="myorderbox-value">{value.status}</span>
                  </div>
                </div>
              </div>
            ))} */}
          </div>
        )}
      </div>
      {/* -------------------------- */}
      <Footer />
    </div>
  );
};

export default Order;
