import React from "react";
import ItemSlider from "../main/home/ItemSlider";
import bannerImage from "../../assets/images/pages/other/bannerImage1.png";

const Banner = ({ title, showItemSlider, givenBackgroundImage }) => {
  return (
    <div>
      <div
        className="bannerSection"
        style={{
          backgroundImage: givenBackgroundImage
            ? `url(${givenBackgroundImage})`
            : `url(${bannerImage})`,
        }}
      >
        {givenBackgroundImage && (
          <div className="bannerSection_blurBackground"></div>
        )}
        <div className="container bannerSection_content">
          <h2 className="bannerSection_heading">{title}</h2>
          {showItemSlider && <ItemSlider />}
        </div>
      </div>
    </div>
  );
};

export default Banner;
