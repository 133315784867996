// eslint-disable-next-line no-unused-vars
import React from "react";
import RenderProduct from "../products/subCmps/RenderProduct";
import { useProductsHook } from "../../../redux/hooks/useProductsHook";
import { useCategories } from "../../../redux/hooks/CategoriesHook";
import { Link } from "react-router-dom";

const HomeSection7 = () => {
  const { allProducts } = useProductsHook();
  const { allCategories } = useCategories();
  return (
    <section>
      <div className="container">
        {/* ---------------------------------------- */}
        <div className="sectionHeading4">
          <center>
            <h4>
              <b>Our Products</b>
            </h4>
          </center>
        </div>
        {/* ---------------------------------------- */}
        {/* ---------------------------------------- */}
        <div className="allProducts row">
          {allProducts
            .filter((value, index) => index < 8)
            .map((value, index) => {
              return (
                <RenderProduct
                  key={index}
                  item={value}
                  AllCategories={allCategories}
                />
              );
            })}
        </div>
        {/* ---------------------------------------- */}
        {/* ---------------------------------------- */}
        <div>
          <center>
            <Link to="/products">
              <button className="home_section3_item_view_morebox_button">
                View All <i className="fa fa-long-arrow-right"></i>
              </button>
            </Link>
          </center>
        </div>
        {/* ---------------------------------------- */}
      </div>
    </section>
  );
};

export default HomeSection7;
