import { SET_FAQS } from "../constants";

const allFaqs = [];
export const faqReducer = (state = allFaqs, action) => {
  switch (action.type) {
    case SET_FAQS:
      return action.data.data;
    default:
      return state;
  }
};
