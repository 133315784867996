import React, { useEffect, useState } from "react";
import ProductCmp1 from "./ProductCmp1";
import "../../../assets/css/pages/product.css";

import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import Banner from "../../components/Banner";
import { Link } from "react-router-dom";
import { useCategories } from "../../../redux/hooks/CategoriesHook";
import { useProductsHook } from "../../../redux/hooks/useProductsHook";
import { useSelectedCategoryHook } from "../../../redux/hooks/useSelectedCategoryHook";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Button } from "react-bootstrap";
import FilterComponent from "./subCmps/FilterComponent";

const BannerWithCustomImage = ({
  givenAllCategories,
  givenSelectedCategory,
}) => {
  const selectedCategoryData = givenAllCategories.filter(
    (value) => value._id === givenSelectedCategory
  );
  if (!selectedCategoryData) {
    return <Banner title="Our Products" showItemSlider={true} />;
  }
  return (
    <Banner
      title="Our Products"
      showItemSlider={true}
      givenBackgroundImage={selectedCategoryData[0].image2}
    />
  );
};

const Products = () => {
  const { allProducts } = useProductsHook();
  const { allCategories } = useCategories();

  const [selectedProducts, setSelectedProducts] = useState([]);
  const { selectedCategory, updateselectedCategory } =
    useSelectedCategoryHook();
  const [searchProduct, setSearchProduct] = useState("");

  // ---------------------------------------------- dropdown list ------------------
  const dropdownList = [
    ["latest", "Latest"],
    ["hightolow", "High To Low"],
    ["lowtohign", "Low To High"],
  ];

  const [selectedDropdownListLabel, setselectedDropdownListLabel] = useState(
    dropdownList[0][1]
  );
  // ---------------------------------------------- dropdown list ------------------

  // ---------------------------------------------- range ------------------
  const [minValue, set_minValue] = useState(0);
  const [maxValue, set_maxValue] = useState(0);
  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
  };
  // ---------------------------------------------- range ------------------

  const getHighestValue = () => {
    if (sessionStorage.getItem("highestValueProduct")) {
      return Number.parseInt(sessionStorage.getItem("highestValueProduct"));
    } else {
      return 0;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (!sessionStorage.getItem("highestValueProduct")) {
        const sortedProduct = allProducts.sort((a, b) => b.price - a.price);
        if (sortedProduct.length > 0) {
          sessionStorage.setItem("highestValueProduct", sortedProduct[0].price);
          set_maxValue(sortedProduct[0].price);
        }
      } else {
        if (maxValue <= 0) {
          set_maxValue(
            Number.parseInt(sessionStorage.getItem("highestValueProduct"))
          );
        }
      }
    }, 2000);

    if (!sessionStorage.getItem("highestValueProduct")) {
      const sortedProduct = allProducts.sort((a, b) => b.price - a.price);
      if (sortedProduct.length > 0) {
        sessionStorage.setItem("highestValueProduct", sortedProduct[0].price);
        set_maxValue(sortedProduct[0].price);
      }
    } else {
      if (maxValue <= 0) {
        set_maxValue(
          Number.parseInt(sessionStorage.getItem("highestValueProduct"))
        );
      }
    }
    setSelectedProducts(
      allProducts.filter((value) => {
        if (!selectedCategory || selectedCategory === "") {
          return true;
        } else {
          return JSON.parse(value.category).includes(selectedCategory);
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxValue, selectedCategory]);

  const handleRemoveFilter = () => {
    updateselectedCategory("");
    setSearchProduct("");
    set_minValue(0);
    set_maxValue(getHighestValue());
  };

  const handleDropdownChange = (newDropdownItem) => {
    if (newDropdownItem === dropdownList[0][0]) {
      setSelectedProducts(
        allProducts.filter((value) => {
          if (!selectedCategory || selectedCategory === "") {
            return true;
          } else {
            return JSON.parse(value.category).includes(selectedCategory);
          }
        })
      );
    } else if (newDropdownItem === dropdownList[1][0]) {
      setSelectedProducts(selectedProducts.sort((a, b) => b.price - a.price));
    } else if (newDropdownItem === dropdownList[2][0]) {
      setSelectedProducts(selectedProducts.sort((a, b) => a.price - b.price));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleGetProductsArray = () => {
    return selectedProducts.filter((value) => {
      if (value.price >= minValue && value.price <= maxValue) {
        if (!searchProduct || searchProduct === "") {
          return true;
        }
        if (value.name.includes(searchProduct)) {
          return true;
        }
      }
      return false;
    });
    // .sort((a, b) => {
    //   if (a.productType === "samagri" && b.productType !== "samagri") {
    //     return 1; // Move 'a' after 'b'
    //   } else if (a.productType !== "samagri" && b.productType === "samagri") {
    //     return -1; // Keep 'a' before 'b'
    //   } else {
    //     return 0; // Keep original order
    //   }
    // });
  };

  return (
    <div>
      <Header />
      {selectedCategory && selectedCategory !== "" ? (
        <BannerWithCustomImage
          givenAllCategories={allCategories}
          givenSelectedCategory={selectedCategory}
        />
      ) : (
        <Banner title="Our Products" showItemSlider={true} />
      )}

      <div className="container   my-3">
        <h5 className="text-primary  wishlistPage_heading">
          <Link to="/" className="text-decoration-none">
            <u>Home</u>
          </Link>
          <span> | </span>
          <span>Products</span>
        </h5>
      </div>
      <div style={{ minHeight: "100vh" }}>
        {/* ========================== filter component ====================== */}
        <div className="container d-flex justify-content-between">
          <div
            style={{
              display: "flex",
              gap: 10,
            }}
          >
            <div>
              <div className="hide-on-col-lg">
                <div className="d-flex " style={{ alignItems: "center" }}>
                  <DropdownButton
                    variant="none"
                    id="dropdown-basic-button"
                    title={
                      <span>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          // width="30"
                          height="25"
                          fill="currentColor"
                          className="bi bi-filter"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5" />
                        </svg>{" "}
                        Filter
                      </span>
                    }
                  >
                    <FilterComponent
                      searchProduct={searchProduct}
                      setSearchProduct={setSearchProduct}
                      minValue={minValue}
                      maxValue={maxValue}
                      highestValueProduct={getHighestValue()}
                      handleInput={handleInput}
                      updateselectedCategory={updateselectedCategory}
                      allProducts={allProducts}
                      allCategories={allCategories}
                      setMinWidth={true}
                    />
                    <Dropdown.Item href="#">
                      <button className="btn btn-success w-100 text-center">
                        Apply
                      </button>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={() => {
                        handleRemoveFilter();
                      }}
                    >
                      <button className="btn btn-warning w-100 text-center">
                        Remove Filter
                      </button>
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
            </div>

            {selectedCategory !== "" && (
              <Button
                onClick={() => {
                  updateselectedCategory("");
                }}
              >
                {
                  allCategories.filter(
                    (value) => value._id === selectedCategory
                  )[0].name
                }{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                </svg>
              </Button>
            )}
          </div>
          <DropdownButton
            variant="none"
            id="dropdown-basic-button"
            title={selectedDropdownListLabel}
          >
            {dropdownList.map((value, index) => (
              <Dropdown.Item
                onClick={() => {
                  setselectedDropdownListLabel(value[1]);
                  handleDropdownChange(value[0]);
                }}
                key={index}
              >
                {value[1]}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </div>
        {/* ========================== filter component ====================== */}
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-0 hide-on-col-md">
              <FilterComponent
                searchProduct={searchProduct}
                setSearchProduct={setSearchProduct}
                minValue={minValue}
                maxValue={maxValue}
                highestValueProduct={getHighestValue()}
                handleInput={handleInput}
                updateselectedCategory={updateselectedCategory}
                allProducts={allProducts}
                allCategories={allCategories}
              />
            </div>
            <div className="col-lg-9 col-md-12">
              <ProductCmp1
                AllProducts={handleGetProductsArray()}
                AllCategories={allCategories}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Products;
