import React from "react";
import ItemSlider from "./ItemSlider";
import "../../../assets/css/pages/home.css";
import HomeSection2 from "./HomeSection2";
import HomeSection3 from "./HomeSection3";
import HomeSection4 from "./HomeSection4";
import HomeSection5 from "./HomeSection5";
import HomeSection6 from "./HomeSection6";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
// import HomeSection1 from "./HomeSection1";
import HomeSection7 from "./HomeSection7";
import HomeSection8 from "./HomeSection8";
import useScrollToTop from "../../../hooks/UseScrollToTop";
import { useProductsHook } from "../../../redux/hooks/useProductsHook";
import HomeSection9 from "./HomeSection9";

const Home = () => {
  // eslint-disable-next-line no-unused-vars
  const { allProducts } = useProductsHook();
  useScrollToTop();
  return (
    <div>
      <Header />
      {/* <HomeSection1 showBackgroundColour={true} /> */}
      <div className="item-slider-box">
        <div className="container py-3">
          <ItemSlider />
        </div>
      </div>
      {/* -------------------- */}
      <HomeSection2 />
      {/* -------------------- */}
      <HomeSection8 />
      {/* -------------------- */}
      <HomeSection9 />
      {/* -------------------- */}
      <HomeSection3 />
      {/* -------------------- */}
      <HomeSection7 />
      {/* -------------------- */}
      <HomeSection4 />
      {/* -------------------- */}
      <HomeSection5 />
      {/* -------------------- */}
      <HomeSection6 />
      {/* -------------------- */}

      <Footer />
    </div>
  );
};

export default Home;
