// ------------   redux ---------------------
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductsList } from "../action";
// ------------   redux ---------------------
export function useProductsHook() {
  const dispatch = useDispatch();
  const allProducts = useSelector((state) => state.productsReducer);

  const updateProductsData = () => {
    dispatch(getProductsList());
  };

  useEffect(() => {
    if (allProducts.length < 1) {
      dispatch(getProductsList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { allProducts, updateProductsData };
}
