/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const EditedTextCmp = ({ givenState }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const globalOnchange = (newEditorState) => {
    // setEditorState(newEditorState);
  };

  useEffect(() => {
    const onChange = (newEditorState) => {
      setEditorState(newEditorState);
    };
    if (givenState && givenState !== "") {
      if (typeof givenState === "string") {
        try {
          const contentState = convertFromRaw(JSON.parse(givenState));
          const newEditorState = EditorState.createWithContent(contentState);
          onChange(newEditorState);
        } catch (e) {
          onChange(() => EditorState.createEmpty());
        }
      }
    }
  }, [givenState]);
  return (
    <span className="editor_text_color">
      <Editor
        toolbarHidden
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={globalOnchange}
      />
    </span>
  );
};

export default EditedTextCmp;
