import React, { useEffect, useState } from "react";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import "../../../assets/css/pages/cartPage.css";
import { useCart } from "./helperFunctions/CartFunctions";
import { useProducts } from "./helperFunctions/ProductHook";
import { Link } from "react-router-dom";
import PaymentTypeRadio from "./checkboxSubComponent/PaymentTypeRadio";
import { useCustomer } from "./helperFunctions/CustomerHook";
import globalVariable from "../../../globalVariable";
import OrderPlacedCmp from "./checkboxSubComponent/OrderPlacedCmp";
import Banner from "../../components/Banner";
import useScrollToTop from "../../../hooks/UseScrollToTop";
import { useDispatch, useSelector } from "react-redux";
import { setProductSelectedPromoCode } from "../../../redux/action";

const RenderTextArea = ({
  givenName,
  givenState,
  givenStateSetter,
  mdVal,
  type,
  isrequired,
}) => (
  <div className={`mb-3 col-md-${mdVal}`}>
    <label htmlFor="exampleInputEmail1" className="form-label">
      {givenName}
      {isrequired && (
        <span style={{ color: "red", fontWeight: "bold" }}> * </span>
      )}
    </label>
    <textarea
      type={type}
      value={givenState}
      onChange={(e) => {
        givenStateSetter(e.target.value);
      }}
      required={isrequired}
      className="form-control"
      id="exampleInputEmail1"
      aria-describedby="emailHelp"
    />
  </div>
);

const RenderInput = ({
  givenName,
  givenState,
  givenStateSetter,
  mdVal,
  type,
  isrequired,
}) => (
  <div className={`mb-3 col-md-${mdVal}`}>
    <label htmlFor="exampleInputEmail1" className="form-label">
      {givenName}
      {isrequired && (
        <span style={{ color: "red", fontWeight: "bold" }}> * </span>
      )}
    </label>
    <input
      type={type}
      value={givenState}
      onChange={(e) => {
        givenStateSetter(e.target.value);
      }}
      required={isrequired}
      className="form-control"
      id="exampleInputEmail1"
      aria-describedby="emailHelp"
    />
  </div>
);

const Checkout = () => {
  const { cart, addToCart, updateCartData } = useCart();
  const { allProducts } = useProducts();
  const { customerData, updateCustomerData } = useCustomer();

  const selectedPromoCode = useSelector(
    (state) => state.productSelectedPromoCodeReducer
  );

  const dispatch = useDispatch();

  const handleGetDiscountValue = (totalCost) => {
    if (selectedPromoCode._id) {
      const discountType = selectedPromoCode.discountType;
      const discountAmount = selectedPromoCode.amount;

      if (discountType === "flat") {
        return Math.ceil(
          Number.parseInt(totalCost) - Number.parseInt(discountAmount)
        );
      } else {
        return Math.ceil(
          Number.parseInt(totalCost) *
            ((100 - Number.parseInt(discountAmount)) / 100)
        );
      }
    } else {
      return Math.ceil(totalCost);
    }
  };

  const handleGetFinalPriceAfterDiscount = (totalCost) => {
    if (selectedPromoCode._id) {
      const discountType = selectedPromoCode.discountType;
      const discountAmount = selectedPromoCode.amount;

      if (discountType === "flat") {
        return Math.floor(
          totalCost -
            (Number.parseInt(totalCost) - Number.parseInt(discountAmount))
        );
      } else {
        return Math.floor(
          totalCost -
            Number.parseInt(totalCost) *
              ((100 - Number.parseInt(discountAmount)) / 100)
        );
      }
    } else {
      return Math.floor(totalCost);
    }
  };

  useScrollToTop();
  //   =========================== local states =========================
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [country, setcountry] = useState("");
  const [street, setstreet] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [pincode, setpincode] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [notes, setnotes] = useState("");
  const [selectedOption, setSelectedOption] = useState("cod");
  const shippingDetailObj = {
    firstName,
    lastName,
    country,
    street,
    city,
    state,
    pincode,
    phone,
    email,
    notes,
    selectedOption,
  };
  const [isOrderPlaced, setIsOrderPlaced] = useState(false);
  const [modalProductsData, setModalProductsData] = useState({});
  //   =========================== local states =========================

  const isProductInCart = (productId, givenCart) => {
    let isExist = givenCart.filter((value) => value._id === productId);
    return isExist[0] ? true : false;
  };

  const getQuantity = (productId, givenCart) => {
    return givenCart.filter((value) => value._id === productId)[0].quantity;
  };

  const handleUpdateQuantity = (process, productData, prevQuantity) => {
    if (process === "add") {
      let newNum = Number.parseInt(prevQuantity) + 1;
      addToCart(productData, newNum);
    } else if (process === "remove") {
      let newNum = Number.parseInt(prevQuantity) - 1;
      addToCart(productData, newNum);
    }
  };

  const getTotalPrice = (givanProducts, givenCart) => {
    const sum = givanProducts
      .filter((value) => isProductInCart(value._id, givenCart))
      .reduce(
        (accumulator, currentValue) =>
          accumulator +
          currentValue.price * getQuantity(currentValue._id, givenCart),
        0
      );
    return sum;
  };

  const getDateTime = () => {
    const currentDate = new Date();
    const todayDate = currentDate.toLocaleDateString();
    const currentTime = currentDate.toLocaleTimeString();
    return { todayDate, currentTime };
  };

  const handlePlaceOrder = (e) => {
    e.preventDefault();

    if (!selectedOption || selectedOption === "") {
      return alert("Please Select Payment Method");
    }
    // alert("your order is placed ");
    const { todayDate, currentTime } = getDateTime();
    const form_data = new FormData();
    const orderProductsArray = allProducts.filter((value) =>
      isProductInCart(value._id, cart)
    );
    form_data.append("shippingDetails", JSON.stringify(shippingDetailObj));
    form_data.append("cartProductsArray", JSON.stringify(cart));
    form_data.append("orderProductsArray", JSON.stringify(orderProductsArray));
    form_data.append("", getTotalPrice(allProducts, cart));
    form_data.append("date", todayDate);
    form_data.append("time", currentTime);

    form_data.append("subtotal", getTotalPrice(allProducts, cart));
    form_data.append("promocode", JSON.stringify(selectedPromoCode));
    form_data.append(
      "discount",
      selectedPromoCode._id
        ? handleGetFinalPriceAfterDiscount(getTotalPrice(allProducts, cart))
        : 0
    );
    form_data.append(
      "orderTotal",
      handleGetDiscountValue(getTotalPrice(allProducts, cart))
    );

    fetch(`${process.env.REACT_APP_BACKEND_1}/customer/save_new-order`, {
      method: "POST",
      body: form_data,
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
      .then((v) => v.json())
      .then((v) => {
        if (v.message === "success") {
          setModalProductsData(v.data);
          setIsOrderPlaced(true);
          updateCartData();
          updateCustomerData();
          dispatch(setProductSelectedPromoCode({}));
        } else {
          if (v.detail) {
            alert(v.detail);
          } else {
            alert("Something Went Wrong! Please Try again.");
          }
        }
      })
      .catch((err) => {
        alert("something went wrong! Please Try again.");
        console.log(err.message);
      });
  };

  useEffect(() => {
    if (customerData.addressObject) {
      if (customerData.addressObject.firstName) {
        setfirstName(customerData.addressObject.firstName);
        setlastName(customerData.addressObject.lastName);
        setcountry(customerData.addressObject.country);
        setstreet(customerData.addressObject.street);
        setcity(customerData.addressObject.city);
        setstate(customerData.addressObject.state);
        setpincode(customerData.addressObject.pincode);
        setphone(customerData.addressObject.phone);
        setemail(customerData.addressObject.email);
      }
    }
  }, [customerData]);

  return (
    <div>
      <Header />
      <Banner title="Checkout" showItemSlider={false} />
      <OrderPlacedCmp
        isOrderPlaced={isOrderPlaced}
        setIsOrderPlaced={setIsOrderPlaced}
        modalProductsData={modalProductsData}
      />
      <div className="container   my-3">
        <h5 className="text-primary  wishlistPage_heading">
          <Link to="/" className="text-decoration-none">
            <u>Home</u>
          </Link>
          <span> | </span>
          <Link to="/cart" className="text-decoration-none">
            <u>Cart</u>
          </Link>
          <span> | </span>
          <span>Checkout</span>
        </h5>
      </div>
      <div style={{ minHeight: "60vh" }}>
        <div className="container my-5">
          <form onSubmit={handlePlaceOrder}>
            <div className="row">
              <div className="col-md-8">
                <div className="checkoutPage">
                  <h3>Shipping Details</h3>
                  <div className="row">
                    <RenderInput
                      givenName="First Name"
                      givenState={firstName}
                      givenStateSetter={setfirstName}
                      mdVal={6}
                      type="text"
                      isrequired={true}
                    />
                    <RenderInput
                      givenName="Last Name"
                      givenState={lastName}
                      givenStateSetter={setlastName}
                      mdVal={6}
                      type="text"
                      isrequired={true}
                    />
                    <RenderInput
                      givenName="Country / Region"
                      givenState={country}
                      givenStateSetter={setcountry}
                      mdVal={6}
                      type="text"
                      isrequired={true}
                    />
                    <RenderInput
                      givenName="Street Address"
                      givenState={street}
                      givenStateSetter={setstreet}
                      mdVal={6}
                      type="text"
                      isrequired={true}
                    />
                    <RenderInput
                      givenName="Town / City"
                      givenState={city}
                      givenStateSetter={setcity}
                      mdVal={6}
                      type="text"
                      isrequired={true}
                    />
                    <RenderInput
                      givenName="State"
                      givenState={state}
                      givenStateSetter={setstate}
                      mdVal={6}
                      type="text"
                      isrequired={true}
                    />
                    <RenderInput
                      givenName="Pincode"
                      givenState={pincode}
                      givenStateSetter={setpincode}
                      mdVal={6}
                      type="number"
                      isrequired={true}
                    />
                    <RenderInput
                      givenName="Phone Number"
                      givenState={phone}
                      givenStateSetter={setphone}
                      mdVal={6}
                      type="number"
                      isrequired={true}
                    />
                    <RenderInput
                      givenName="Email Address"
                      givenState={email}
                      givenStateSetter={setemail}
                      mdVal={12}
                      type="email"
                      isrequired={false}
                    />
                    <RenderTextArea
                      givenName="Order Notes (optional)"
                      givenState={notes}
                      givenStateSetter={setnotes}
                      mdVal={12}
                      type="textarea"
                      isrequired={false}
                    />
                  </div>
                  {/* <button type="submit" className="btn btn-primary">
                  Submit
                </button> */}
                </div>
              </div>
              <div className="col-md-4">
                <h3>Your Order</h3>
                <div className="cart_summary_box">
                  <div className="section1">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col" className="boldTh">
                              Product
                            </th>
                            <th scope="col" className="boldTh">
                              Quantity
                            </th>
                            <th scope="col" className="boldTh">
                              Subtotal
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {allProducts
                            .filter((value) => isProductInCart(value._id, cart))
                            .map((value, index) => {
                              return (
                                <tr className="cart_table-row" key={index}>
                                  <td>
                                    <div className="cart_align-div">
                                      <span
                                        style={{
                                          backgroundImage: `url('${value.image}')`,
                                        }}
                                        className="cart_product-img"
                                      ></span>
                                      {/* <h6>{value.name}</h6> */}
                                    </div>
                                  </td>
                                  {/* <td>
                                <div className="cart_align-div">
                                  <h6>{value.price}</h6>
                                </div>
                              </td> */}
                                  <td>
                                    <div className="cart_align-div">
                                      <span
                                        className="product_modal_desc_Box2_sec1_body_black cart_quantity-icons"
                                        onClick={() => {
                                          handleUpdateQuantity(
                                            "remove",
                                            value,
                                            getQuantity(value._id, cart)
                                          );
                                        }}
                                      >
                                        <i className="fa fa-minus"></i>
                                      </span>
                                      <span className="product_modal_desc_Box2_sec1_body_white cart_quantity">
                                        <h6>{getQuantity(value._id, cart)}</h6>
                                      </span>
                                      <span
                                        className="product_modal_desc_Box2_sec1_body_black cart_quantity-icons"
                                        onClick={() => {
                                          handleUpdateQuantity(
                                            "add",
                                            value,
                                            getQuantity(value._id, cart)
                                          );
                                        }}
                                      >
                                        <i className="fa fa-plus"></i>
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="cart_align-div">
                                      <h6>
                                        {value.price *
                                          getQuantity(value._id, cart)}{" "}
                                        Rs
                                      </h6>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr />
                  <div className="section1">
                    <h6>Subtotal </h6>
                    <h6>
                      <span className="subtotal">
                        ₹ {getTotalPrice(allProducts, cart)}.00
                      </span>
                    </h6>
                  </div>
                  <hr />
                  {/* <div className="section1">
                    <h6>Shipping Chaget </h6>
                    <h6>
                      <span className="subtotal">₹ 00.00</span>
                    </h6>
                  </div>
                  <hr /> */}

                  {selectedPromoCode._id && (
                    <div>
                      <div className="my-2">
                        <span className="text-success">
                          promo code applied : {selectedPromoCode.name}
                        </span>
                      </div>
                      <hr />
                    </div>
                  )}
                  {selectedPromoCode._id && (
                    <div>
                      <div className="section1">
                        <h6>discount </h6>
                        <h6>
                          <span className="subtotal">
                            ₹{" "}
                            {handleGetFinalPriceAfterDiscount(
                              getTotalPrice(allProducts, cart)
                            ) + 0}
                            .00
                          </span>
                        </h6>
                      </div>
                      <hr />
                    </div>
                  )}

                  <div className="section1">
                    <h6>Total </h6>
                    <h6>
                      {selectedPromoCode._id ? (
                        <span className="subtotal">
                          ₹{" "}
                          {handleGetDiscountValue(
                            getTotalPrice(allProducts, cart)
                          ) + 0}
                          .00
                        </span>
                      ) : (
                        <span className="subtotal">
                          ₹ {getTotalPrice(allProducts, cart) + 0}.00
                        </span>
                      )}
                    </h6>
                  </div>
                  <hr />
                  <div>
                    <div className="section1">
                      <h6>Payment Method </h6>
                    </div>
                    <PaymentTypeRadio
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                    />
                  </div>
                  <hr />
                  {cart.length > 0 ? (
                    <button type="submit" className="cart_proceed-btn my-2 p-1">
                      Place order
                    </button>
                  ) : (
                    <Link to="/products">
                      <button
                        type="button"
                        className="cart_proceed-btn my-2 p-1"
                      >
                        Add Products To Cart
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Checkout;
