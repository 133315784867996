import React from "react";
import backgroundImage from "../../../assets/images/dummy/homesection1img2.png";
import backgroundImage4 from "../../../assets/images/dummy/homeimg4.png";
import backgroundImage5 from "../../../assets/images/dummy/homeimg5.png";
import backgroundImage6 from "../../../assets/images/dummy/homeimg6.png";
import backgroundImage7 from "../../../assets/images/dummy/homeimg7.png";
import backgroundImage8 from "../../../assets/images/dummy/homeimg8.png";
import { Link } from "react-router-dom";
import { useBasicSetup } from "../../../redux/hooks/BasicSetupHook";
import HomeSection2Cmp1 from "./subCmps/HomeSection2Cmp1";

const HomeSection2 = () => {
  const { BasicDetailData } = useBasicSetup();
  return (
    <div className="mb-5">
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className="HomeSection2">


          <HomeSection2Cmp1 />


        </div>
      </div>
      <div className="HomeSection2_hoverBox">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <Link to="/products" style={{ textDecoration: "none" }}>
                <div
                  className="HomeSection2_hoverBoxSec1"
                  style={{ backgroundImage: `url(${backgroundImage4})` }}
                >
                  <div className="HomeSection2_hoverBoxSec1_div">
                    <h4 className="HomeSection2_hoverBoxSec1_left">30% Off</h4>
                    <h5 className="HomeSection2_hoverBoxSec1_right">
                      Book Your Order and Get 30% Off
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-5">
              <div
                className="HomeSection2_hoverBoxSec2"
                style={{ backgroundImage: `url(${backgroundImage5})` }}
              >
                <div
                  className="HomeSection2_hoverBoxSec2_mobileImage"
                  style={{ backgroundImage: `url(${backgroundImage6})` }}
                ></div>
                <div className="HomeSection2_hoverBoxSec2_div">
                  <h5 className="HomeSection2_hoverBoxSec2_top">
                    Switch to the app!
                  </h5>
                  <div className="HomeSection2_hoverBoxSec2_imgBox">
                    <a
                      href={BasicDetailData.androidAppLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="HomeSection2_hoverBoxSec2_imgBox_img1"
                        src={backgroundImage7}
                        alt=""
                      />
                    </a>
                    <a
                      href={BasicDetailData.iosAppLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="HomeSection2_hoverBoxSec2_imgBox_img2"
                        src={backgroundImage8}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSection2;
