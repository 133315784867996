import React from "react";
import { Link } from "react-router-dom";

import mailImage from "../../../../assets/images/pages/checkout/mailImage.png";
import { useDispatch } from "react-redux";
import { setCurrentCart } from "../../../../redux/action";
const { useNavigate } = require("react-router-dom");

const PackageOrderPlacedCmp = ({
  isOrderPlaced,
  setIsOrderPlaced,
  modalPackageData,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div style={{ display: isOrderPlaced ? "block" : "none" }}>
      <div
        style={{
          height: "100vh",
          position: "fixed",
          zIndex: 10000,
          top: 0,
          left: 0,
          width: "100vw",
          backgroundColor: "rgba(0,0,0,0.1)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="col-md-6 px-3"
          style={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "40px 0px",
          }}
        >
          <h2 style={{ textAlign: "center" }}>
            <b>
              Your Booking <br />
              Placed Successfully
            </b>
          </h2>

          <img src={mailImage} style={{ width: "200px" }} alt="" />
          <small>Our pandit will contact you for further details.</small>
          <div className="my-3">
            <center>
              <h5>
                <b>Summary</b>
              </h5>
            </center>
            <div className="mb-3">
              <h6>
                <b>Booking Id</b>: {modalPackageData._id}
              </h6>
            </div>
            <div className="mb-3">
              <h6>
                <b>Total Price</b>: {modalPackageData.orderTotal} Rs
              </h6>
            </div>
            <div className="mb-3">
              <h6 className="mb-0">
                <b>Packages</b>:
              </h6>
              <div>
                {modalPackageData.packageInfo &&
                  Array.isArray(modalPackageData.packageInfo) &&
                  modalPackageData.packageInfo.map((value2, index2) => {
                    return (
                      <div key={index2}>
                        <b>{index2 + 1}.</b> <span>{value2.package.title}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="mb-4">
            <h6>
              <b>
                <Link
                  to="/contact-us"
                  style={{
                    textDecoration: "underline",
                    color: "inherit",
                    fontWeight: "700",
                    marginTop: 10,
                  }}
                  onClick={() => {
                    setIsOrderPlaced(false);
                  }}
                >
                  Contact Us For Support
                </Link>
              </b>
            </h6>
          </div>
          <button
            className="btn btn-primary"
            style={{
              backgroundColor: "rgb(106,17,16)",
              border: "none",
              minWidth: "270px",
            }}
            onClick={() => {
              navigate("/orders");
              dispatch(setCurrentCart("packages"));
              setIsOrderPlaced(false);
            }}
          >
            View Booking
          </button>
          <Link
            to="/"
            style={{
              textDecoration: "underline",
              color: "inherit",
              fontWeight: "400",
              marginTop: 10,
            }}
            onClick={() => {
              setIsOrderPlaced(false);
            }}
          >
            Go To Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PackageOrderPlacedCmp;
