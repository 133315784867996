// ------------   redux ---------------------
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTestimonialsList } from "../action";
// ------------   redux ---------------------
export function useTestimonials() {
  const dispatch = useDispatch();
  const allTestimonials = useSelector((state) => state.testimonialsReducer);

  const updateTestimonials = () => {
    dispatch(getTestimonialsList());
  };

  useEffect(() => {
    if (allTestimonials.length < 1) {
      dispatch(getTestimonialsList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { allTestimonials, updateTestimonials };
}
