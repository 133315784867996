import { SET_PACKAGE_ADD_ONS } from "../constants";

const allPackagesAddOns = [];
export const packageAddOnsReducer = (state = allPackagesAddOns, action) => {
    switch (action.type) {
        case SET_PACKAGE_ADD_ONS:
            return action.data.data;
        default:
            return state;
    }
};
