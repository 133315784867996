// eslint-disable-next-line no-unused-vars
import React from "react";
import RenderProduct from "./subCmps/RenderProduct";
import RenderProductSamagri from "./subCmps/RenderProductSamagri";
import RenderProductSamagri2 from "./subCmps/RenderProductSamagri2";

const ProductCmp1 = ({ AllProducts, AllCategories }) => {
  return (
    <section>
      <div>
        <div className="allProducts row">
          {AllProducts.filter((v) => v.productType !== "samagri").map(
            (value, index) => {
              return (
                <RenderProduct
                  key={index}
                  item={value}
                  AllCategories={AllCategories}
                  show3={true}
                />
              );
            }
          )}
        </div>
        <div className="mt-4 mb-2 container">
          <div>
            <h5>
              <b>All Samagri</b>
            </h5>
          </div>
          <div className=" ">
            <div className="allProducts_section_sec_1">
              <div className=" allProducts  row">
                {AllProducts.filter((v) => v.productType === "samagri").map(
                  (value, index) => {
                    return (
                      <RenderProductSamagri
                        key={index}
                        selectedItem={value}
                        AllCategories={AllCategories}
                      />
                    );
                  }
                )}
              </div>
            </div>
            <div className="allProducts_section_sec_2">
              <div className=" allProducts  row">
                {AllProducts.filter((v) => v.productType === "samagri").map(
                  (value, index) => {
                    return (
                      <RenderProductSamagri2
                        key={index}
                        selectedItem={value}
                        AllCategories={AllCategories}
                      />
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductCmp1;
