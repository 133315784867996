// ------------   redux ---------------------
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStatesList } from "../action";
// ------------   redux ---------------------
export function useStateHook() {
  const dispatch = useDispatch();
  const allStates = useSelector((state) => state.statesReducer);

  const updateStatesData = () => {
    dispatch(getStatesList());
  };

  useEffect(() => {
    if (allStates.length < 1) {
      dispatch(getStatesList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { allStates, updateStatesData };
}
