// ------------   redux ---------------------
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPanditList } from "../action";
// ------------   redux ---------------------
export function usePanditHook() {
  const dispatch = useDispatch();
  const allPandits = useSelector((state) => state.panditReducer);

  const updatePanditsData = () => {
    dispatch(getPanditList());
  };

  useEffect(() => {
    if (allPandits.length < 1) {
      dispatch(getPanditList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { allPandits, updatePanditsData };
}
