import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useWishlist } from "../helperFunctions/WishlistFunctions";
import { Link } from "react-router-dom";
import { useCart } from "../helperFunctions/CartFunctions";
import EditedTextCmp from "../../../components/EditedTextCmp";

function ViewProductModal({ show, setShow, selectedItem, AllCategories }) {
  const [imageArray, setImageArray] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const { wishlist, addToWishlist, removeFromWishlist } = useWishlist();
  const { cart, addToCart, removeFromCart } = useCart();

  const handleUpdateQuantity = (process) => {
    if (process === "add") {
      setQuantity((prevQuantity) => prevQuantity + 1);
    } else if (process === "remove") {
      if (quantity >= 1) {
        setQuantity((prevQuantity) => prevQuantity - 1);
      }
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleSwichImage = (index) => {
    let prevArray = imageArray;
    let hold = prevArray[0];
    prevArray[0] = prevArray[index];
    prevArray[index] = hold;

    setImageArray([]);
    setTimeout(() => {
      setImageArray(prevArray);
    }, 1);
  };

  useEffect(() => {
    setImageArray([]);
    if (selectedItem.name) {
      for (let i = 1; i <= 5; i++) {
        let imageName = `image`;
        if (i > 1) {
          imageName = `image${i}`;
        }

        if (selectedItem[imageName]) {
          // console.log(selectedItem[imageName])
          setImageArray((prevItem) => [...prevItem, selectedItem[imageName]]);
        }
      }
    }
  }, [selectedItem]);

  const handleAddToWishList = (productData) => {
    if (addToWishlist(productData)) {
    } else {
      setErrorMessage("Please Login To Add Product To Wishlist!");
    }
  };
  const handleRemoveToWishList = (productData) => {
    if (removeFromWishlist(productData)) {
    } else {
      setErrorMessage("Please Login To Remove Product From Wishlist!");
    }
  };

  const handleAddToCart = (productData) => {
    if (addToCart(productData, quantity)) {
    } else {
      setErrorMessage("Please Login To Add Product To Wishlist!");
    }
  };

  const handleRemoveFromCart = (productData) => {
    if (removeFromCart(productData)) {
    } else {
      setErrorMessage("Please Login To Remove Product From Cart!");
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} className="product_modal ">
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          {selectedItem.name && (
            <div className="product_modal_box">
              <div className="row product_modal_container">
                <div className="col-md-6 product_modal_imageBox">
                  <div
                    className="product_modal_selectedImage"
                    style={{ backgroundImage: `url('${imageArray[0]}')` }}
                  >
                    {imageArray.map((value, index) => {
                      if (index === 0) {
                        return "";
                      } else {
                        return (
                          <div
                            key={index}
                            className="product_modal_PendingImage"
                            onClick={() => {
                              handleSwichImage(index);
                            }}
                            style={{
                              backgroundImage: `url('${imageArray[index]}')`,
                            }}
                          ></div>
                        );
                      }
                    })}
                  </div>
                </div>
                <div className="col-md-6 product_modal_desc my-2">
                  <div className="d-flex product_modal_desc_Box1">
                    <span className="product_modal_desc_Box1_sec1">
                      Sale 20% Off
                    </span>
                    <span
                      className="product_modal_desc_Box1_sec2"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      <i className="fa fa-close"></i>
                    </span>
                  </div>
                  <h3 className="product_modal_desc_name mt-3 mb-2">
                    {selectedItem.name}
                  </h3>
                  <p className="product_modal_desc_description">
                    <EditedTextCmp givenState={selectedItem.description} />
                  </p>
                  <div className="product_modal_desc_Box2">
                    <div className="product_modal_desc_Box2_sec1">
                      <div className="product_modal_desc_Box2_sec1_heading">
                        price
                      </div>
                      <div className="product_modal_desc_Box2_sec1_body">
                        <span className="product_modal_desc_Box2_sec1_body_sec1">
                          ₹{selectedItem.price}
                        </span>
                        <span className="product_modal_desc_Box2_sec1_body_sec2">
                          <del>₹{selectedItem.mrp}</del>
                        </span>
                        <span
                          className="product_modal_desc_Box2_sec1_body_sec1"
                          style={{ fontSize: "1rem" }}
                        >
                          / {selectedItem.quaniityPerunit} {selectedItem.unit}
                        </span>
                      </div>
                    </div>
                    <div className="product_modal_desc_Box2_sec2">
                      <div className="product_modal_desc_Box2_sec1_heading">
                        {selectedItem.quaniityPerunit * quantity} {selectedItem.unit}
                      </div>
                      <div className="product_modal_desc_Box2_sec1_body product_modal_desc_Box2_sec1_body_icon">
                        <span
                          className="product_modal_desc_Box2_sec1_body_black"
                          onClick={() => {
                            handleUpdateQuantity("remove");
                          }}
                        >
                          <i className="fa fa-minus"></i>
                        </span>
                        <span className="product_modal_desc_Box2_sec1_body_white">
                          {quantity}
                        </span>
                        <span
                          className="product_modal_desc_Box2_sec1_body_black"
                          onClick={() => {
                            handleUpdateQuantity("add");
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="product_modal_button_box">
                    {cart.filter((value11) => {
                      return value11._id === selectedItem._id;
                    }).length > 0 ? (
                      <button
                        className="product_modal_button_cart"
                        onClick={() => {
                          handleRemoveFromCart(selectedItem);
                        }}
                      >
                        Remove From Cart
                      </button>
                    ) : (
                      <button
                        className="product_modal_button_cart"
                        onClick={() => {
                          handleAddToCart(selectedItem);
                        }}
                      >
                        Add To Cart
                      </button>
                    )}

                    {wishlist.includes(selectedItem._id) ? (
                      <button
                        className="product_modal_button_wishlist"
                        onClick={() => {
                          handleRemoveToWishList(selectedItem);
                        }}
                      >
                        <i className="fa fa-heart text-danger"></i>
                        Remove From Wishlist
                      </button>
                    ) : (
                      <button
                        className="product_modal_button_wishlist"
                        onClick={() => {
                          handleAddToWishList(selectedItem);
                        }}
                      >
                        <i className="fa fa-heart-o"></i>
                        Add To Wishlist
                      </button>
                    )}
                  </div>
                  <div>
                    {errorMessage && errorMessage !== "" && (
                      <div className="product_modal_error_box">
                        <p className="text-danger mb-0 mt-2 product_modal_error_box_sec1">
                          {errorMessage}
                        </p>
                        <p className="product_modal_error_box_sec2">
                          <Link to="/login" className="text-warning">
                            Click Here To Login
                          </Link>
                        </p>
                      </div>
                    )}
                  </div>
                  <div>
                    <hr />
                    <span className="product_modal_desc_Box2_sec1_heading">
                      Category : &nbsp;
                    </span>
                    {selectedItem.category &&
                      JSON.parse(selectedItem.category).length > 0 &&
                      JSON.parse(selectedItem.category).map(
                        (value2, index2) => {
                          return AllCategories.map((value3) => {
                            if (value3._id === value2) {
                              return <span key={index2}> {value3.name}, </span>;
                            } else {
                              return "";
                            }
                          });
                        }
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
}

export default ViewProductModal;
