import CryptoJS from "crypto-js";

// Encryption Key (Make sure to keep it secure)
const encryptionKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;

// Function to encrypt JWT token
export const encryptToken = (token) => {
  const encryptedToken = CryptoJS.AES.encrypt(token, encryptionKey).toString();
  return encryptedToken;
};

// Function to decrypt JWT token
export const decryptToken = (encryptedToken) => {
  const bytes = CryptoJS.AES.decrypt(encryptedToken, encryptionKey);
  const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedToken;
};
