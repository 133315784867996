import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function LanguageModal({
  visibleLanguageModal,
  setvisibleLanguageModal,
  data,
  handleEnqureNowFunction,
}) {
  const handleClose = () => setvisibleLanguageModal(false);

  const handleEnqureNow = (e) => {
    e.preventDefault();
    handleEnqureNowFunction();
  };

  return (
    <>
      <Modal show={visibleLanguageModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleEnqureNow}>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Name
              </label>
              <input
                type="text"
                required
                value={data.userName}
                onChange={(e) => {
                  data.setuserName(e.target.value);
                }}
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Mobile Number
              </label>
              <input
                type="text"
                required
                value={data.anotherMobile}
                onChange={(e) => {
                  data.setanotherMobile(e.target.value);
                }}
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Make Enquiry
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LanguageModal;
