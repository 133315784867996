import { useEffect } from "react";
// ------------   redux ---------------------
import { getCartData, getCustomerData } from "../../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
// ------------   redux ---------------------
import globalVariable from "../../../../globalVariable";

// A custom hook for handling Cart
export function useCart() {
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.customerDataReducer);
  const cart = useSelector((state) => state.cartReducer);

  useEffect(() => {
    if (!customerData.mobile) {
      dispatch(getCustomerData());
    }
    if (cart.length < 0) {
      dispatch(getCartData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateCartData = () => {
    dispatch(getCartData());
  };

  const addToCart = (productData, quantity) => {
    if (customerData.mobile) {
      const form_data = new FormData();
      form_data.append("product_id", productData._id);
      form_data.append("product_qty", quantity);
      fetch(`${process.env.REACT_APP_BACKEND_1}/customer/add-cart`, {
        method: "POST",
        body: form_data,
        headers: new Headers({
          Authorization: `Bearer ${globalVariable.accessToken}`,
        }),
      })
        .then((v) => v.json())
        .then((v) => {
          if (v.message === "success") {
            dispatch(getCartData());
          } else {
            return false;
          }
        })
        .catch((err) => {
          console.log(err.message);
          return false;
        });
      return true;
    } else {
      return false;
    }
  };

  const removeFromCart = (productData) => {
    if (customerData.mobile) {
      const form_data = new FormData();
      form_data.append("product_id", productData._id);
      fetch(`${process.env.REACT_APP_BACKEND_1}/customer/remove-cart`, {
        method: "POST",
        body: form_data,
        headers: new Headers({
          Authorization: `Bearer ${globalVariable.accessToken}`,
        }),
      })
        .then((v) => v.json())
        .then((v) => {
          if (v.message === "success") {
            dispatch(getCartData());
          } else {
            return false;
          }
        })
        .catch((err) => {
          console.log(err.message);
          return false;
        });
      return true;
    } else {
      return false;
    }
  };

  return { cart, addToCart, removeFromCart, updateCartData };
}
