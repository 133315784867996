import { SET_GALLERIES } from "../constants";

const galleriesData = [];
export const galleriesReducer = (state = galleriesData, action) => {
  switch (action.type) {
    case SET_GALLERIES:
      return action.data.data;
    default:
      return state;
  }
};
