// ------------   redux ---------------------
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPackagesOrdersData } from "../redux/action";
// ------------   redux ---------------------
export function usePackageOrders() {
  const dispatch = useDispatch();
  const allPackageOrders = useSelector((state) => state.packageOrderReducer);

  const updatePackageOrders = () => {
    dispatch(getPackagesOrdersData());
  };

  useEffect(() => {
    if (allPackageOrders.length < 1) {
      dispatch(getPackagesOrdersData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { allPackageOrders, updatePackageOrders };
}
