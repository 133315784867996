import React from "react";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import { Link } from "react-router-dom";
import Banner from "../../components/Banner";
import useScrollToTop from "../../../hooks/UseScrollToTop";
import { useGalleriesHook } from "../../../redux/hooks/useGalleriesHook";

const Galleries = () => {
  useScrollToTop();
  const { allGalleries } = useGalleriesHook();
  return (
    <div>
      <Header />
      <Banner title={"Galleries"} showItemSlider={false} />
      <div className="container mt-5">
        <div className="container   my-3">
          <h5 className="text-primary  wishlistPage_heading">
            <Link to="/" className="text-decoration-none">
              <u>Home</u>
            </Link>
            <span> | </span>
            <span>galleries</span>
          </h5>
        </div>
        <div>
          <div className="my-3">
            <div className="row">
              {allGalleries
                .filter((v) => v.status !== "inactive")
                .sort((a, b) => b.priority - a.priority)
                .map((value, index) => {
                  return (
                    <div className="col-md-3">
                      <div
                        style={{ backgroundImage: `url('${value.image}')` }}
                        className="div_bg_img mb-3"
                      >
                        <img
                          src={value.image}
                          className="w-100"
                          style={{ aspectRatio: 1, visibility: "hidden" }}
                          alt=""
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      {/* -------------------------- */}

      <Footer />
    </div>
  );
};

export default Galleries;
