// ------------   redux ---------------------
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesList } from "../action";
// ------------   redux ---------------------
export function useCategories() {
  const dispatch = useDispatch();
  const allCategories = useSelector((state) => state.categoriesReducer);

  const updateCategories = () => {
    dispatch(getCategoriesList());
  };

  useEffect(() => {
    if (allCategories.length < 1) {
      dispatch(getCategoriesList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { allCategories, updateCategories };
}
