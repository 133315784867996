import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { getDateTimeInStringFormat } from "../../../../helpers/date&time_functions/dateTimeFunctions";

const RenderInput = ({
    givenName,
    givenState,
    givenStateSetter,
    mdVal,
    type,
    isrequired,
}) => (
    <div className={`mb-3 col-md-${mdVal}`}>
        <label htmlFor="exampleInputEmail1" className="form-label">
            {givenName}
            {isrequired && (
                <span style={{ color: "red", fontWeight: "bold" }}> * </span>
            )}
        </label>
        <input
            type={type}
            value={givenState}
            onChange={(e) => {
                givenStateSetter(e.target.value);
            }}
            required={isrequired}
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
        />
    </div>
);

const RenderTextArea = ({
    givenName,
    givenState,
    givenStateSetter,
    mdVal,
    type,
    isrequired,
}) => (
    <div className={`mb-3 col-md-${mdVal}`}>
        <label htmlFor="exampleInputEmail1" className="form-label">
            {givenName}
            {isrequired && (
                <span style={{ color: "red", fontWeight: "bold" }}> * </span>
            )}
        </label>
        <textarea
            type={type}
            value={givenState}
            onChange={(e) => {
                givenStateSetter(e.target.value);
            }}
            required={isrequired}
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
        />
    </div>
);

function SendChatCallRequest({ show, setShow, method, panditValue }) {
    const customerData = useSelector((state) => state.customerDataReducer);

    const [email, setemail] = useState('');
    const [phone, setphone] = useState(0);
    const [name, setname] = useState('');
    const [message, setmessage] = useState('');


    useEffect(() => {

        if (customerData.email) {
            setemail(customerData.email)
        }
        if (customerData.addressObject) {

            setphone(customerData.addressObject.phone ? customerData.addressObject.phone : '')
            setname((customerData.addressObject.firstName ? customerData.addressObject.firstName : '') + (customerData.addressObject.lastName ? customerData.addressObject.lastName : ''))
        }
    }, [customerData])

    const handleClose = () => {
        setShow(false);
    }

    const handleSendRequest = () => {
        const url = `${process.env.REACT_APP_BACKEND_1}/guest/panditcontactrequest`;

        const formBody = new FormData();
        if (!name || name === '') {
            return alert('Please enter your name');
        }
        if (!phone || phone === '') {
            return alert('Please enter your phone');
        }
        formBody.append('name', name);
        formBody.append('phone', phone);
        formBody.append('email', email);

        formBody.append('method', method)
        formBody.append('message', message)

        formBody.append('panditName', panditValue.name);
        formBody.append('pandit_id', panditValue._id);

        formBody.append('date_time', getDateTimeInStringFormat())


        fetch(url, {
            method: 'POST',
            body: formBody,
        }).then((v) => v.json()).then((v) => {
            if (v.message === 'success') {
                handleClose();
                setTimeout(() => {
                    alert('Request Received! We will connect with you soon.');
                }, 100);
            } else {
                alert('Something went wrong.')
            }
        }).catch((err) => {
            alert("Something went wrong! Please try again later.");
        })
    }

    return (
        <div>
            <Modal show={show} onHide={handleClose} className="SendChatCallRequest_modal ">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span style={{ fontWeight: 500, textTransform: 'capitalize' }}>
                            Send {method}&nbsp;
                        </span>
                        Request To
                        &nbsp;
                        <span style={{ fontWeight: 500, textTransform: 'capitalize' }}>
                            {panditValue.name}&nbsp;
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RenderInput
                        givenName="Full Name"
                        givenState={name}
                        givenStateSetter={setname}
                        mdVal={12}
                        type="text"
                        isrequired={true}
                    />
                    <RenderInput
                        givenName="Phone Number"
                        givenState={phone}
                        givenStateSetter={setphone}
                        mdVal={12}
                        type="number"
                        isrequired={true}
                    />
                    <RenderTextArea
                        givenName="Message"
                        givenState={message}
                        givenStateSetter={setmessage}
                        mdVal={12}
                        type="textarea"
                        isrequired={false}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSendRequest}>
                        Send
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default SendChatCallRequest;
