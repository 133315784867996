import { SET_FORM_LANGUAGE } from "../constants";

const formLanguageData = "";
export const formLanguageReducer = (state = formLanguageData, action) => {
  switch (action.type) {
    case SET_FORM_LANGUAGE:
      return action.data;
    default:
      return state;
  }
};
