import { SET_LANGUAGES } from "../constants";

const allLanguages = [];
export const languagesReducer = (state = allLanguages, action) => {
  switch (action.type) {
    case SET_LANGUAGES:
      return action.data.data;
    default:
      return state;
  }
};
