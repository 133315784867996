import { useEffect } from 'react';

function useScrollToTop() {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    const handleLinkClick = () => {
      scrollToTop(); // Scroll to the top
    };

    // Add event listener to handle clicks on all anchor tags
    document.querySelectorAll('a').forEach((link) => {
      link.addEventListener('click', handleLinkClick);
    });

    // Cleanup: Remove event listeners when the component unmounts
    return () => {
      document.querySelectorAll('a').forEach((link) => {
        link.removeEventListener('click', handleLinkClick);
      });
    };
  }, []); // Run effect only once after initial render
}

export default useScrollToTop;