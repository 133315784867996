import React from "react";

function PaymentTypeRadio({ selectedOption, setSelectedOption }) {
  const paymentMethodArray = [
    { name: "Cash On Delivery", value: "cod", isactive: true },
    { name: "Online Payment", value: "online", isactive: false },
  ];

  return (
    <div>
      {paymentMethodArray.map((value, index) => (
        <div className="form-check" key={index}>
          <input
            className="form-check-input"
            value={value.value}
            required
            checked={selectedOption === value.value}
            onChange={(e) => {
              value.isactive
                ? setSelectedOption(e.target.value)
                : alert(
                    `${value.name} Method is currently not available! Please select a different payment method.`
                  );
            }}
            type="radio"
            id="flexRadioDefault1"
          />
          <label className="form-check-label" htmlFor="flexRadioDefault1">
            {value.name}
          </label>
        </div>
      ))}
    </div>
  );
}

export default PaymentTypeRadio;
