import { SET_PACKAGES_ORDERS } from "../constants";

const packageOrderData = [];
export const packageOrderReducer = (state = packageOrderData, action) => {
  switch (action.type) {
    case SET_PACKAGES_ORDERS:
      return action.data.data;
    default:
      return state;
  }
};
