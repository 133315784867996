import React from "react";
import { usePanditHook } from "../../../redux/hooks/usePanditHook";
import { Link } from "react-router-dom";

const RenderPandits = ({ value }) => (
  <div className="col-lg-3 col-md-6">
    <div className="homeSection8PanditItem " style={{ height: "100%" }}>
      <Link
        to={`pandit/${value._id}`}
        style={{
          textDecoration: "none",
          color: "inherit",
          display: "block",
          height: "100%",
        }}
      >
        <div
          className="homeSection8PanditItem_innerDiv"
          style={{ height: "100%" }}
        >
          <div
            className="homeSection8PanditItem_borderDiv"
            style={{ height: "100%" }}
          >
            <div>
              <img
                className="homeSection8PanditItem_img"
                src={value.image}
                alt=""
              />
            </div>
            <span className="homeSection8PanditItem_name">{value.name}</span>
            <span className="homeSection8PanditItem_specializaion">
              {value.education}
            </span>
          </div>
        </div>
      </Link>
    </div>
  </div>
);

const HomeSection8 = () => {
  const { allPandits } = usePanditHook();
  return (
    <div className="container">
      {/* ---------------------------------------- */}
      <div className="sectionHeading4">
        <center>
          <h4>
            <b>Our Pandit</b>
          </h4>
          <p>Best Pandit from india</p>
        </center>
      </div>
      {/* ---------------------------------------- */}
      <div className="homeSection8PanditBox row">
        {allPandits
          .filter((value, index) => index < 8)
          .map((value, index) => (
            <RenderPandits key={index} value={value} />
          ))}
      </div>
      {/* ---------------------------------------- */}
      <div>
        <center>
          <Link to="/pandits">
            <button className="home_section3_item_view_morebox_button">
              View All <i className="fa fa-long-arrow-right"></i>
            </button>
          </Link>
        </center>
      </div>
      {/* ---------------------------------------- */}
    </div>
  );
};

export default HomeSection8;
