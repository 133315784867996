// ------------   redux ---------------------
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersData } from "../redux/action";
// ------------   redux ---------------------
export function useOrders() {
  const dispatch = useDispatch();
  const allOrders = useSelector((state) => state.orderReducer);

  const updateOrders = () => {
    dispatch(getOrdersData());
  };

  useEffect(() => {
    if (allOrders.length < 1) {
      dispatch(getOrdersData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { allOrders, updateOrders };
}
