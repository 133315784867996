

export const getDateTimeInStringFormat = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export function formatDateToISOString(date) {
    // Get the ISO string of the date
    const isoString = date.toISOString();

    // Slice to get the 'YYYY-MM-DDTHH:MM' format
    const formattedDate = isoString.slice(0, 16);

    return formattedDate;
}

export function convertStringFormatDateInDateAndTime(dateStr) {
    const date = new Date(dateStr);

    // Get the day of the week
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const day = days[date.getDay()];

    // Get the time in HH:MM:SS format
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const time = `${hours}:${minutes}:${seconds}`;

    return { day, time };
}



export function getTimeDifference(datetime1, datetime2) {
    // Convert the datetime strings to Date objects
    const date1 = new Date(datetime1);
    const date2 = new Date(datetime2);

    // Calculate the difference in milliseconds
    const diffInMs = Math.abs(date2 - date1);

    // Convert the difference to minutes and seconds
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInSeconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

    // Return the result as an object
    return {
        minutes: diffInMinutes,
        seconds: diffInSeconds,
    };
}

export function formated_DMY_date(datetimeString) {
    const dateObject = new Date(datetimeString);

    // Extract the day, month, and year
    const day = String(dateObject.getDate()).padStart(2, '0');
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const year = dateObject.getFullYear();

    // Format as day-month-year
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
}

export function formated_HM_date(datetimeString) {
    const dateObject = new Date(datetimeString);

    // Extract the hours and minutes
    const hours = String(dateObject.getHours()).padStart(2, '0');
    const minutes = String(dateObject.getMinutes()).padStart(2, '0');

    // Format as hr:min
    const formattedTime = `${hours}:${minutes}`;
    return formattedTime;
}

export function getDateInDM() {
    const now = new Date();
    const day = now.getDate();
    const month = now.toLocaleString('default', { month: 'long' });

    return `${day} ${month}`;
}


export function isDayOrNight() {
    const now = new Date();
    const hour = now.getHours();

    // Define day and night hours
    const dayStart = 6; // 6 AM
    const dayEnd = 18;  // 6 PM

    if (hour >= dayStart && hour < dayEnd) {
        return 'day';
    } else {
        return 'night';
    }
}


export function getTimeOfDay() {
    const now = new Date();
    const hour = now.getHours();

    if (hour >= 5 && hour < 12) {
        return 'Morning';
    } else if (hour >= 12 && hour < 17) {
        return 'Afternoon';
    } else if (hour >= 17 && hour < 21) {
        return 'Evening';
    } else {
        return 'Night';
    }
}
