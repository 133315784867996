import ViewProductModal from "./ViewProductModal";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useCart } from "../helperFunctions/CartFunctions";

const RenderProduct = ({ item, AllCategories, show3 }) => {
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const { cart } = useCart();

  return (
    <div
      className={`allProducts_item p-3 ${
        show3 ? "col-lg-4 col-md-6" : "col-lg-3 col-md-6"
      }  `}
    >
      <div className="allProducts_item_Box">
        <div
          className="allProducts_item_img_imgBox"
          style={{ backgroundImage: `url('${item.image}')` }}
        >
          <img className="allProducts_item_img" src={item.image} alt="" />
        </div>
        <div className="allProducts_item_description">
          <h6 className="allProducts_item_name mt-2">{item.name}</h6>
          <h6 className="allProducts_item_price">₹{item.price}</h6>
        </div>
        {cart.filter((value11) => {
          return value11._id === item._id;
        }).length > 0 ? (
          <Link to="/cart">
            <button className="allProducts_item_addToCartButton">
              Go To Cart
            </button>
          </Link>
        ) : (
          <button
            onClick={() => {
              setShow(true);
              setSelectedItem(item);
            }}
            className="allProducts_item_addToCartButton"
          >
            Add To Cart
          </button>
        )}
      </div>
      <ViewProductModal
        show={show}
        setShow={setShow}
        selectedItem={selectedItem}
        AllCategories={AllCategories}
      />
    </div>
  );
};

export default RenderProduct;
