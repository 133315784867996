// ------------   redux ---------------------
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBasicDetail } from "../action";
// ------------   redux ---------------------

export function useBasicSetup() {
  const dispatch = useDispatch();
  const BasicDetailData = useSelector((state) => state.basicSetup);

  const updateBasicDetailData = () => {
    dispatch(getBasicDetail());
  };

  useEffect(() => {
    if (Object.keys(BasicDetailData).length < 1) {
      dispatch(getBasicDetail());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { BasicDetailData, updateBasicDetailData };
}
