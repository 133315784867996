import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { useCategories } from "../../../redux/hooks/CategoriesHook";
import { useSelectedCategoryHook } from "../../../redux/hooks/useSelectedCategoryHook";
// import '../../../assets/css/pages/home.css'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const ItemSlider = () => {
  const { allCategories } = useCategories();
  const { updateselectedCategory } = useSelectedCategoryHook();
  return (
    <div>
      <Carousel responsive={responsive}>
        {allCategories.map((value, index) => (
          <div
            className="itemSlider"
            onClick={() => {
              updateselectedCategory(value._id);
            }}
            key={index}
          >
            <Link to={"/products"} className="normal_link">
              <img src={value.image} alt="" />
            </Link>
            <span>
              <Link
                to={"/products"}
                style={{ fontWeight: "inherit", color: "inherit" }}
                className="normal_link"
              >
                {value.name}
              </Link>
            </span>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ItemSlider;
