// ------------   redux ---------------------
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllGalleries } from "../action";
// ------------   redux ---------------------
export function useGalleriesHook() {
  const dispatch = useDispatch();
  const allGalleries = useSelector((state) => state.galleriesReducer);

  const updateGalleriesData = () => {
    dispatch(getAllGalleries());
  };

  useEffect(() => {
    if (allGalleries.length < 1) {
      dispatch(getAllGalleries());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { allGalleries, updateGalleriesData };
}
