/* eslint-disable react/prop-types */

import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

const handleResponsive = (givenResponsiveArray) => {
  let value = givenResponsiveArray.filter((value) => {
    if (value.resolution.length === 1) {
      if (
        useMediaQuery({
          minWidth: value.resolution[0],
        })
      ) {
        return value.value;
      }
    } else if (
      useMediaQuery({
        minWidth: value.resolution[0],
        maxWidth: value.resolution[1],
      })
    ) {
      return value.value;
    }
    return false;
  });
  if (value.length > 0) {
    return 100 / value[0].value;
  }
  return 33.3333;
};

const responsiveArray = [{ resolution: [0], value: 1 }];

// =========================== element Code | start =======================
const RenderElement = ({ value, index }) => (
  <div
    className="react_slider_2l4234nl_HomeSection2Cmp1"
    id={`${index === 0 ? "start_width_0px_HomeSection2Cmp1" : ""}`}
    style={{ minWidth: `${handleResponsive(responsiveArray)}%` }}
  >
    <div>
      <div className="row">
        <div className="col-sm-5 HomeSection2_sec sec_1">
          <div className="pt-5 pb-5">
            <h4 style={{ fontWeight: "500", color: "rgb(106,17,16)" }}>
              {value.title1} <br /> {value.title2}
            </h4>
            <h1 style={{ fontWeight: "bold", color: "rgb(106,17,16)" }}>
              {value.desc}
            </h1>
          </div>
        </div>
        <div className="col-sm-7 HomeSection2_sec sec_2">
          <img src={value.image} alt="" />
        </div>
      </div>
    </div>
  </div>
);

const RenderElementInText = (givenValue) => {
  return `
   <div>
      <div class="row">
        <div class="col-sm-5 HomeSection2_sec sec_1">
          <div class="pt-5 pb-5">
            <h4  style="font-weight: 500;color: 'rgb(106,17,16)'">
              ${givenValue.title1}  <br />  ${givenValue.title2}
            </h4>
            <h1  style="font-weight: bold;color: 'rgb(106,17,16)'">
              ${givenValue.desc}
            </h1>
          </div>
        </div>
        <div class="col-sm-7 HomeSection2_sec sec_2">
          <img
            src="${givenValue.image}"
            alt=""
          />
        </div>
      </div>
    </div>`;
};
// =========================== element Code | end =======================

const HomeSection2Cmp1 = () => {
  // const [marginLeft, setMarginLeft] = useState();
  const storedData = [
    {
      title1: "**Experience Divine Blessings with Pujanya**",
      title2:
        "Your one-stop destination for all puja needs! From quality puja samagri to expert pandits, we ensure a seamless and spiritual experience.",
      desc: 'Order now and bring home divine grace"',
      image: require("../../../../assets/images/pages/home/homeSection2_img1.jpg"),
    },
    {
      title1: "**पूजनीय के साथ पाएं दिव्य आशीर्वाद**",
      title2:
        "आपकी सभी पूजा आवश्यकताओं के लिए एकमात्र स्थान! गुणवत्तापूर्ण पूजा सामग्री से लेकर अनुभवी पंडितों तक, हम एक सहज और आध्यात्मिक अनुभव की गारंटी देते हैं।",
      desc: 'अभी ऑर्डर करें और घर लाएं दिव्य कृपा।"',
      image: require("../../../../assets/images/pages/home/homeSection2_img2.jpeg"),
    },
    {
      title1: "**পূজন্যের সাথে পান দেবতাদের আশীর্বাদ**",
      title2:
        "আপনার সমস্ত পূজার প্রয়োজনের জন্য একমাত্র গন্তব্য! গুণগত মানের পূজা সামগ্রী থেকে শুরু করে অভিজ্ঞ পণ্ডিত, আমরা একটি সহজ এবং আধ্যাত্মিক অভিজ্ঞতা নিশ্চিত করি।",
      desc: 'এখনই অর্ডার করুন এবং বাড়িতে নিয়ে আসুন দেবতার আশীর্বাদ।"',
      image: require("../../../../assets/images/pages/home/homeSection2_img1.jpg"),
    },
    {
      title1:
        '"Transform your spiritual rituals with Pooja Samagri delivered to your doorstep and expert Pandit services from Poojanya - your trusted partner in devotion."',
      title2: " ",
      desc: "Book now!",
      image: require("../../../../assets/images/dummy/homesection1img1.png"),
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [childLength, setChildLength] = useState(0);
  const [acData, setAcData] = useState([]);
  const [wait1Sec, setWait1Sec] = useState(true);
  const currentDeviceWidth = handleResponsive(responsiveArray);

  const handleInfinity = () => {
    setWait1Sec(false);
    setTimeout(() => {
      setWait1Sec(true);
    }, 500);
  };

  useEffect(() => {
    const storeDataLength = storedData.length;
    if (storeDataLength > 0) {
      let newstoredData = [];
      if (storeDataLength > 1) {
        newstoredData = [
          storedData[storeDataLength - 2],
          storedData[storeDataLength - 1],
          ...storedData,
        ];
      } else {
        if (storeDataLength > 1) {
          newstoredData = [
            storedData[storeDataLength - 1],
            storedData[storeDataLength - 1],
            ...storedData,
          ];
        }
      }

      // newstoredData.push();
      if (newstoredData.length < 7) {
        const loopCount = 8 - newstoredData.length;
        if (loopCount > 0) {
          let max = 0;
          for (let i = 0; i < loopCount; i++) {
            if (!storedData[i]) {
              if (!storedData[max]) {
                max = 0;
              }
              newstoredData.push(storedData[max]);
              max++;
            } else {
              newstoredData.push(storedData[i]);
            }
          }
        }
      }

      setAcData(newstoredData);
    }

    setTimeout(() => {
      setInterval(() => {
        const thisInfiniteElement = document.getElementById(
          "react_slider_2l4234nl_HomeSection2Cmp1_right_btn"
        );
        if (thisInfiniteElement) {
          thisInfiniteElement.click();
        }
      }, 7000);
    }, 2000);
    // setChildrenArr(parentDiv.childNodes);
    setChildLength(storeDataLength);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLeft = () => {
    if (!wait1Sec) {
      return;
    }
    // let newDoc = setMarginLeft((prevMargin) => prevMargin + 33.66);
    const parentDiv = document.getElementById(
      "react_slider_2l4234nl_HomeSection2Cmp1_parent_id"
    );
    parentDiv.children[parentDiv.children.length - 1].remove();

    let prevIdDocument = document.getElementById(
      "start_width_0px_HomeSection2Cmp1"
    );
    let para = document.createElement("div");
    para.classList.add("react_slider_2l4234nl_HomeSection2Cmp1");
    para.style.minWidth = `${currentDeviceWidth}%`;
    para.setAttribute("id", "start_width_0px_HomeSection2Cmp1");
    const thisElementData = storedData[childLength - currentIndex - 1];
    para.innerHTML = RenderElementInText(thisElementData);
    prevIdDocument.removeAttribute("id");
    parentDiv.prepend(para);

    prevIdDocument.style.maxHeight = 0;
    setTimeout(() => {
      prevIdDocument.style.maxHeight = "unset";
    }, 1000);
    prevIdDocument.removeAttribute("id");
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentIndex(childLength - 1);
    }
  };

  const handleRight = () => {
    if (!wait1Sec) {
      return;
    }
    const parentDiv = document.getElementById(
      "react_slider_2l4234nl_HomeSection2Cmp1_parent_id"
    );
    let prevElement = document.getElementById(
      "start_width_0px_HomeSection2Cmp1"
    );
    let prevElementSibling = prevElement.nextElementSibling;
    prevElementSibling.setAttribute("id", "start_width_0px_HomeSection2Cmp1");
    prevElementSibling.classList.add("height__0");
    prevElement.remove();
    let para = document.createElement("div");
    para.classList.add("react_slider_2l4234nl_HomeSection2Cmp1");
    para.style.minWidth = `${currentDeviceWidth}%`;
    const thisElementData = storedData[currentIndex];
    para.innerHTML = RenderElementInText(thisElementData);
    parentDiv.append(para);
    setTimeout(() => {
      prevElementSibling.classList.remove("height__0");
    }, 1000);

    if (currentIndex < childLength - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  };
  return (
    <div>
      {childLength > 0 && (
        <div>
          <div style={{ width: "100%", overflow: "hidden" }}>
            <div
              id="react_slider_2l4234nl_HomeSection2Cmp1_parent_id"
              style={{
                marginLeft: `-${currentDeviceWidth}%`,
                display: "flex",
                width: "100%",
                transition: "1s ease-out",
              }}
            >
              {acData.map((value, index) => {
                return (
                  <RenderElement value={value} index={index} key={index} />
                );
              })}
            </div>
          </div>
          {/* ============== direction buttons ================= */}
          <div className="my-5" style={{ display: "none" }}>
            <center>
              <button
                onClick={() => {
                  handleLeft();
                  handleInfinity();
                }}
              >
                left
              </button>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              <button
                id="react_slider_2l4234nl_HomeSection2Cmp1_right_btn"
                onClick={() => {
                  handleRight();
                  handleInfinity();
                }}
              >
                right
              </button>
            </center>
          </div>
          {/* ============== direction buttons ================= */}
          {/* ============== current index ================= */}
          <center style={{ display: "none" }}>
            <div>
              {storedData.map((value, index) => {
                return (
                  <span
                    key={index}
                    style={{
                      display: "inline-block",
                      backgroundColor: currentIndex === index ? "red" : "black",
                      height: 10,
                      width: 10,
                    }}
                  ></span>
                );
              })}
            </div>
          </center>
          {/* ============== current index ================= */}
        </div>
      )}
    </div>
  );
};

export default HomeSection2Cmp1;
