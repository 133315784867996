import React from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import MultiRangeSlider from "multi-range-slider-react";

const FilterComponent = ({
  searchProduct,
  setSearchProduct,
  minValue,
  maxValue,
  highestValueProduct,
  handleInput,
  updateselectedCategory,
  allProducts,
  allCategories,
  setMinWidth,
}) => {
  return (
    <div>
      <div className="m-3 hide-on-col-md">
        <span>
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            // width="30"
            height="25"
            fill="currentColor"
            className="bi bi-filter"
            viewBox="0 0 16 16"
          >
            <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5" />
          </svg>{" "}
          Filter
        </span>
      </div>
      <div style={setMinWidth && { minWidth: "250px" }} className="m-3">
        <InputGroup className="mb-3">
          <Form.Control
            placeholder="Search Product"
            aria-label="Search Product"
            aria-describedby="basic-addon2"
            value={searchProduct}
            onChange={(e) => {
              setSearchProduct(e.target.value);
            }}
          />
          <Button
            className="btn btn-secondary"
            onClick={() => {
              setSearchProduct("");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
            </svg>
          </Button>
        </InputGroup>

        <div className="mt-3">
          <div>
            <span>
              <b>Range : </b> ₹{minValue} - ₹{maxValue}
            </span>
          </div>
          <MultiRangeSlider
            style={{ boxShadow: "none", borderWidth: "0px" }}
            barLeftColor="grey"
            barInnerColor="black"
            barRightColor="grey"
            min={0}
            max={highestValueProduct}
            step={highestValueProduct / 100}
            minValue={minValue}
            maxValue={maxValue}
            ruler={false}
            onInput={(e) => {
              handleInput(e);
            }}
          />
        </div>
        <div className="mt-3">
          <div
            className="d-flex  justify-content-between my-2"
            style={{ cursor: "pointer" }}
            onClick={() => {
              updateselectedCategory("");
            }}
          >
            <b>All Category</b>
            <b>({allProducts.length})</b>
          </div>
          {allCategories.map((value, index) => {
            const allProductsInThisCategory = allProducts.filter((value22) =>
              JSON.parse(value22.category).includes(value._id)
            );
            return (
              <div
                key={index}
                className="d-flex  justify-content-between my-2"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  updateselectedCategory(value._id);
                }}
              >
                <b>{value.name}</b>
                <b>({allProductsInThisCategory.length})</b>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;
