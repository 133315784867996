/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useWishlist } from "../helperFunctions/WishlistFunctions";
import { Link } from "react-router-dom";
import { useCart } from "../helperFunctions/CartFunctions";
import EditedTextCmp from "../../../components/EditedTextCmp";

function RenderProductSamagri2({ selectedItem, AllCategories }) {
  const [imageArray, setImageArray] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const { wishlist, addToWishlist, removeFromWishlist } = useWishlist();
  const { cart, addToCart, removeFromCart } = useCart();

  const handleUpdateQuantity = (process) => {
    if (process === "add") {
      setQuantity((prevQuantity) => prevQuantity + 1);
    } else if (process === "remove") {
      if (quantity >= 1) {
        setQuantity((prevQuantity) => prevQuantity - 1);
      }
    }
  };

  //   const handleSwichImage = (index) => {
  //     let prevArray = imageArray;
  //     let hold = prevArray[0];
  //     prevArray[0] = prevArray[index];
  //     prevArray[index] = hold;

  //     setImageArray([]);
  //     setTimeout(() => {
  //       setImageArray(prevArray);
  //     }, 1);
  //   };

  useEffect(() => {
    setImageArray([]);
    if (selectedItem.name) {
      for (let i = 1; i <= 5; i++) {
        let imageName = `image`;
        if (i > 1) {
          imageName = `image${i}`;
        }

        if (selectedItem[imageName]) {
          // console.log(selectedItem[imageName])
          setImageArray((prevItem) => [...prevItem, selectedItem[imageName]]);
        }
      }
    }
  }, [selectedItem]);

  const handleAddToWishList = (productData) => {
    if (addToWishlist(productData)) {
    } else {
      setErrorMessage("Please Login To Add Product To Wishlist!");
    }
  };
  const handleRemoveToWishList = (productData) => {
    if (removeFromWishlist(productData)) {
    } else {
      setErrorMessage("Please Login To Remove Product From Wishlist!");
    }
  };

  const handleAddToCart = (productData) => {
    if (addToCart(productData, quantity)) {
    } else {
      setErrorMessage("Please Login To Add Product To Wishlist!");
    }
  };

  const handleRemoveFromCart = (productData) => {
    if (removeFromCart(productData)) {
    } else {
      setErrorMessage("Please Login To Remove Product From Cart!");
    }
  };

  return (
    <div className="w-50">
      <div className="m-1">
        {selectedItem.name && (
          <div>
            <div className="  product_modal_container ">
              <div className="row product_modal_desc my-2  product_samagri_container_box">
                {/* <div className="d-flex product_modal_desc_Box1">
                <span className="product_modal_desc_Box1_sec1">
                  Sale 20% Off
                </span>
                <span
                  className="product_modal_desc_Box1_sec2"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <i className="fa fa-close"></i>
                </span>
              </div> */}
                <div className="col-md-3 px-0">
                  <div
                    className="product_modal_selectedImage product_modal_selectedImage_samagri "
                    style={{ backgroundImage: `url('${imageArray[0]}')` }}
                  >
                    {/* {imageArray.map((value, index) => {
                  if (index === 0) {
                    return "";
                  } else {
                    return (
                      <div
                        key={index}
                        className="product_modal_PendingImage"
                        onClick={() => {
                          handleSwichImage(index);
                        }}
                        style={{
                          backgroundImage: `url('${imageArray[index]}')`,
                        }}
                      ></div>
                    );
                  }
                })} */}
                  </div>
                </div>

                <div className="col-md-9">
                  <div className="row">
                    <div className="col-sm-12 col-md-8 col-lg-8">
                      <div>
                        <span
                          className="product_modal_desc_Box2_sec1_body_sec1"
                          style={{ fontSize: "0.7rem" }}
                        >
                          {selectedItem.quaniityPerunit} {selectedItem.unit}
                        </span>
                        <h6 className="product_modal_desc_name mb-2">
                          {selectedItem.name}
                        </h6>
                        {/* <p className="product_modal_desc_description">
                          <EditedTextCmp
                            givenState={selectedItem.description}
                          />
                        </p> */}
                      </div>
                      {/* --------------- price  */}
                      <div className="">
                        <div className="">
                          <h6
                            style={{ fontSize: "0.8rem" }}
                            className="product_modal_desc_Box2_sec1_heading"
                          >
                            price
                          </h6>
                          <div className="" style={{ fontSize: "0.8rem" }}>
                            <span className="">
                              <b>₹{selectedItem.price}</b>
                            </span>
                            <span className="product_modal_desc_Box2_sec1_body_sec2">
                              <del>₹{selectedItem.mrp}</del>
                            </span>
                            <br />
                          </div>
                        </div>
                        {/* <div className="product_modal_desc_Box2_sec2">
                          <div className="product_modal_desc_Box2_sec1_heading">
                            {selectedItem.quaniityPerunit * quantity}{" "}
                            {selectedItem.unit}
                          </div>
                          <div className="product_modal_desc_Box2_sec1_body product_modal_desc_Box2_sec1_body_icon">
                            <span
                              className="product_modal_desc_Box2_sec1_body_black"
                              onClick={() => {
                                handleUpdateQuantity("remove");
                              }}
                            >
                              <i className="fa fa-minus"></i>
                            </span>
                            <span className="product_modal_desc_Box2_sec1_body_white">
                              {quantity}
                            </span>
                            <span
                              className="product_modal_desc_Box2_sec1_body_black"
                              onClick={() => {
                                handleUpdateQuantity("add");
                              }}
                            >
                              <i className="fa fa-plus"></i>
                            </span>
                          </div>
                        </div> */}
                      </div>
                      {/* --------------- price  */}
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 product_samagri_btns mb-2">
                      <div className="product_samagri_btns_rating">
                        {/* <p>4.7 Rating</p> */}
                      </div>
                      <div>
                        <div className="product_modal_button_box">
                          {cart.filter((value11) => {
                            return value11._id === selectedItem._id;
                          }).length > 0 ? (
                            <button
                              className="product_modal_button_cart w-100"
                              onClick={() => {
                                handleRemoveFromCart(selectedItem);
                              }}
                            >
                              Remove
                            </button>
                          ) : (
                            <button
                              className="product_modal_button_cart w-100"
                              onClick={() => {
                                handleAddToCart(selectedItem);
                              }}
                            >
                              Add
                            </button>
                          )}

                          {/* {wishlist.includes(selectedItem._id) ? (
                            <button
                              className="product_modal_button_wishlist mx-0 w-100"
                              onClick={() => {
                                handleRemoveToWishList(selectedItem);
                              }}
                            >
                              <i className="fa fa-heart text-danger"></i>
                              Remove From Wishlist
                            </button>
                          ) : (
                            <button
                              className="product_modal_button_wishlist mx-0 w-100"
                              onClick={() => {
                                handleAddToWishList(selectedItem);
                              }}
                            >
                              <i className="fa fa-heart-o"></i>
                              Add To Wishlist
                            </button>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* -------------------- */}
                  <div>
                    {errorMessage && errorMessage !== "" && (
                      <div className="product_modal_error_box">
                        <p className="text-danger mb-0 mt-2 product_modal_error_box_sec1">
                          {errorMessage}
                        </p>
                        <p className="product_modal_error_box_sec2">
                          <Link to="/login" className="text-warning">
                            Click Here To Login
                          </Link>
                        </p>
                      </div>
                    )}
                  </div>
                  {/* <div className="mb-2">
                  <hr />
                  <span className="product_modal_desc_Box2_sec1_heading">
                    Category : &nbsp;
                  </span>
                  {selectedItem.category &&
                    JSON.parse(selectedItem.category).length > 0 &&
                    JSON.parse(selectedItem.category).map((value2, index2) => {
                      return AllCategories.map((value3) => {
                        if (value3._id === value2) {
                          return <span key={index2}> {value3.name}, </span>;
                        } else {
                          return "";
                        }
                      });
                    })}
                </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RenderProductSamagri2;
