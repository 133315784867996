import { SET_FORM_PUJA_CATEGORY } from "../constants";

const formPujaCategoryData = "";
export const formPujaCategoryReducer = (
  state = formPujaCategoryData,
  action
) => {
  switch (action.type) {
    case SET_FORM_PUJA_CATEGORY:
      return action.data;
    default:
      return state;
  }
};
