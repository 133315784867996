// ------------   redux ---------------------
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogs } from "../action";
// ------------   redux ---------------------
export function useBlogHook() {
  const dispatch = useDispatch();
  const allBlogs = useSelector((state) => state.blogsReducer);

  const updateBlogsData = () => {
    dispatch(getAllBlogs());
  };

  useEffect(() => {
    if (allBlogs.length < 1) {
      dispatch(getAllBlogs());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { allBlogs, updateBlogsData };
}
