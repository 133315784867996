import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Routing from "./Routing";
import "./assets/css/style.css";
import { VerifyToken } from "./auth/VerifyToken";
// ------------   redux ---------------------
import { getCustomerData, getWishlistData, getCartData } from "./redux/action";
import { useDispatch } from "react-redux";
import globalVariable from "./globalVariable";
// ------------   redux ---------------------

const App = () => {
  const dispatch = useDispatch();

  const staterFunction = () => {
    VerifyToken(() => {
      dispatch(getCustomerData());
      dispatch(getWishlistData());
      dispatch(getCartData());
      sessionStorage.setItem("tried", "ok");
    });
    globalVariable.REACT_APP_BACKEND_1 = process.env.REACT_APP_BACKEND_1;
  };

  useEffect(() => {
    staterFunction();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <BrowserRouter>
      <Routing />
    </BrowserRouter>
  );
};

export default App;
