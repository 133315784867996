import React from "react";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import { useBasicSetup } from "../../../redux/hooks/BasicSetupHook";
import { Link } from "react-router-dom";
import EditedTextCmp from "../../components/EditedTextCmp";
import Banner from "../../components/Banner";

const TermsAndCondition = () => {
  const { BasicDetailData } = useBasicSetup();
  return (
    <div>
      <Header />
      <Banner
        title={BasicDetailData.cancellationTitle}
        showItemSlider={false}
      />
      <div className="container mt-5">
        <div className="container   my-3">
          <h5 className="text-primary  wishlistPage_heading">
            <Link to="/" className="text-decoration-none">
              <u>Home</u>
            </Link>
            <span> | </span>
            <span>Cancelation & Refund Policy</span>
          </h5>
        </div>
      </div>
      {/* -------------------------- */}
      <div>
        <div className="container mb-5 mt-2">
          <EditedTextCmp givenState={BasicDetailData.cancellationDescription} />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TermsAndCondition;
