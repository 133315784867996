// ------------   redux ---------------------
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPackages } from "../action";
// ------------   redux ---------------------
export function usePackageHook() {
  const dispatch = useDispatch();
  const allPackages = useSelector((state) => state.packageReducer);

  const updatePackagesData = () => {
    dispatch(getAllPackages());
  };

  useEffect(() => {
    if (allPackages.length < 1) {
      dispatch(getAllPackages());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { allPackages, updatePackagesData };
}
