import React, { useState } from "react";
import { Rating } from "react-simple-star-rating";
import globalVariable from "../../../../globalVariable";

export const PanditRatingNew = ({
  panditId,
  isUpdated,
  handleFetchReviewData,
}) => {
  const [initialRating, setInitialRating] = useState(0);
  const [review, setReview] = useState("");

  const handleSendReview = () => {
    console.log("====================================");
    console.log(review);
    console.log("====================================");
    if (initialRating === 0) {
      return alert("Please select rating!");
    }

    const bodyData = new FormData();
    bodyData.append("panditId", panditId);
    bodyData.append("ratingValue", initialRating);
    bodyData.append("review", review);

    const url = `${process.env.REACT_APP_BACKEND_1}/customer/pandit/newrating`;

    fetch(url, {
      method: "POST",
      body: bodyData,
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
      .then((v) => v.json())
      .then((v) => {
        if (v.message === "success") {
          alert("Success! Thanks for Rating.");
        } else {
          if (v.detail) {
            alert(v.detail);
            handleFetchReviewData(!isUpdated);
            setInitialRating(0);
            setReview("");
          } else {
            alert("Something went wrong! Please try again.");
          }
        }
      })
      .catch((err) => {
        alert("Something went wrong! Please try again.");
        console.log(err.message);
      });
  };

  return (
    <div>
      <div className="my-3">
        <Rating
          initialValue={initialRating}
          onClick={setInitialRating}
          size={30}
        />
      </div>
      <div>
        <textarea
          className="form-control w-100"
          name=""
          id=""
          value={review}
          onChange={(e) => {
            setReview(e.target.value);
          }}
        />
      </div>
      <div className="my-3">
        <button
          onClick={() => {
            handleSendReview();
          }}
          className="btn btn-success"
        >
          Submit
        </button>
      </div>
    </div>
  );
};
