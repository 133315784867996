import { useEffect } from "react";
// ------------   redux ---------------------
import { getProductsList } from "../../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
// ------------   redux ---------------------
export function useProducts() {
  const dispatch = useDispatch();
  const allProducts = useSelector((state) => state.productsReducer);

  const updateProductList = () => {
    dispatch(getProductsList());
  };

  useEffect(() => {
    if (allProducts.length < 1) {
      dispatch(getProductsList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { allProducts, updateProductList };
}
