import { SET_SELECTED_CATEGORY } from "../constants";

const selectedCategories = "";
export const selectedCategoryReducer = (state = selectedCategories, action) => {
  switch (action.type) {
    case SET_SELECTED_CATEGORY:
      return action.data;
    default:
      return state;
  }
};
