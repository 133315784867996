import { SET_BASIC_SETUP } from "../constants";

const basicSetupData = {};
export const basicSetup = (state = basicSetupData, action) => {
  switch (action.type) {
    case SET_BASIC_SETUP:
      return action.data.data;
    default:
      return state;
  }
};
