import { SET_CART } from "../constants";

const allCartData = [];
export const cartReducer = (state = allCartData, action) => {
  switch (action.type) {
    case SET_CART:
      return action.data.data;
    default:
      return state;
  }
};
