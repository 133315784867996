// ------------   redux ---------------------
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFaqsList } from "../action";
// ------------   redux ---------------------
export function useFaqs() {
  const dispatch = useDispatch();
  const allFaqs = useSelector((state) => state.faqReducer);

  const updateFaqs = () => {
    dispatch(getFaqsList());
  };

  useEffect(() => {
    if (allFaqs.length < 1) {
      dispatch(getFaqsList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { allFaqs, updateFaqs };
}
