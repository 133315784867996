import React, { useEffect, useState } from "react";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import "../../../assets/css/pages/cartPage.css";
import { useCart } from "./helperFunctions/CartFunctions";
import { useProducts } from "./helperFunctions/ProductHook";
import { Link, useNavigate } from "react-router-dom";
import Banner from "../../components/Banner";
import useScrollToTop from "../../../hooks/UseScrollToTop";
import { useDispatch, useSelector } from "react-redux";
import {
  addPromoCodeInPackage,
  getAllCustomerPackageCart,
  getAllPromoCodes,
  setCurrentCart,
  setProductSelectedPromoCode,
} from "../../../redux/action";
import EditedTextCmp from "../../components/EditedTextCmp";
import BookNowModal from "../home/subCmps/BookNowModal";
import { useCustomerDataHook } from "../../../redux/hooks/useCustomerDataHook";
import globalVariable from "../../../globalVariable";
import PackageOrderPlacedCmp from "./checkboxSubComponent/PackageOrderPlacedCmp";
import { usePackageOrders } from "../../../hooks/PackageOrderHook";

const RenderPackageData = ({
  givenPackageData,
  allPackageAddOns,
  handleRemoveThisPackag,
  allPromoCodes,
}) => {
  const packageData = givenPackageData.package;
  const dispatch = useDispatch();
  const [selectedArray, setselectedArray] = useState([]);

  const [promoCodeValue, setPromoCodeValue] = useState("");
  const [errMessage, setErrMessage] = useState("");

  const getThisPromoCodes = () => {
    return allPromoCodes.filter((v) => {
      if (v.productOrPackage !== "package") {
        return false;
      }
      if (v.targetId === "all") {
        return true;
      }
      if (v.targetId === packageData._id) {
        return true;
      }
      return false;
    });
  };

  const applyPromoCode = (givenPromoCode) => {
    dispatch(
      addPromoCodeInPackage({
        packageId: packageData._id,
        givenPromoCode: givenPromoCode,
      })
    );
  };

  const emptyPromoCode = () => {
    dispatch(
      addPromoCodeInPackage({
        packageId: packageData._id,
        givenPromoCode: {},
      })
    );
  };

  const handleApplyPromoCode = () => {
    setErrMessage("");
    const newArr = getThisPromoCodes().filter((v) => {
      if (v.code === promoCodeValue) {
        return true;
      } else {
        return false;
      }
    });

    if (newArr.length > 0) {
      const currentTotalCost = getTotalCost(selectedArray, packageData.price);
      const minAmount = newArr[0].minOrderValue;
      if (currentTotalCost < minAmount) {
        emptyPromoCode();
        setErrMessage(
          `minimum package amount is ${minAmount}. Add puja samagri.`
        );
      } else {
        applyPromoCode(newArr[0]);
      }
    } else {
      emptyPromoCode();
      setErrMessage("Invalid Promo Code!");
    }
  };

  const getTotalCost = (addOnArray, initialPrice) => {
    const addOnTotal = addOnArray.reduce((total, num) => {
      return total + Number.parseInt(num.price);
    }, 0);
    return Math.ceil(addOnTotal + initialPrice);
  };

  const handleGetTotalCostAfterPromoCode = (addOnArray, initialPrice) => {
    const addOnTotal = addOnArray.reduce((total, num) => {
      return total + Number.parseInt(num.price);
    }, 0);

    const handleGetTotalCostAfterPromoCode = (totalCost) => {
      if (givenPackageData.promoCode && givenPackageData.promoCode._id) {
        const discountType = givenPackageData.promoCode.discountType;
        const discountAmount = givenPackageData.promoCode.amount;

        if (discountType === "flat") {
          return Math.ceil(
            Number.parseInt(totalCost) - Number.parseInt(discountAmount)
          );
        } else {
          return Math.ceil(
            Number.parseInt(totalCost) *
              ((100 - Number.parseInt(discountAmount)) / 100)
          );
        }
      } else {
        return Math.ceil(totalCost);
      }
    };

    return Math.ceil(
      addOnTotal + handleGetTotalCostAfterPromoCode(initialPrice)
    );
  };

  // const isIncluded = (givenArray, givenObj) => {
  //   return givenArray.filter((value) => value._id === givenObj._id)
  // }

  // const handleAddOrRemoveFromSelectedArray = (addOnObj) => {

  //   let isExist = isIncluded(selectedArray, addOnObj);
  //   if (isExist.length > 0) {
  //     let newArray = selectedArray.filter((value) => value._id !== addOnObj._id)
  //     setselectedArray(newArray);
  //   } else {
  //     setselectedArray([...selectedArray, addOnObj])
  //   }
  //   setTimeout(() => {

  //     console.log('====================================');
  //     console.log(selectedArray);
  //     console.log('====================================');
  //   }, 1000)
  // }

  useEffect(() => {
    setselectedArray(givenPackageData.addonArray);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!packageData) {
    return <></>;
  }

  return (
    <div className="front_package_inf packageDetails_box_item my-2 ">
      <div className="packageDetails_box_item_item">
        <div className="accordion-item">
          <h2
            className="accordion-header"
            id={`heading_faq_${packageData._id}`}
          >
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse_faq_${packageData._id}`}
              aria-expanded="true"
              aria-controls={`collapse_faq_${packageData._id}`}
            >
              <div className="w-100">
                <div className="d-flex justify-content-between w-100">
                  <div>
                    <h5 className="mb-0">{packageData.title}</h5>

                    <p className="packageDetails_box_item_item_description">
                      <EditedTextCmp givenState={packageData.description} />
                    </p>
                  </div>
                  <div>
                    <div
                      style={{
                        textDecorationLine:
                          givenPackageData.promoCode &&
                          givenPackageData.promoCode._id
                            ? "line-through"
                            : "",
                      }}
                    >
                      <h5>
                        <b>₹&nbsp;</b>
                        <b style={{ color: "rgb(206,14,45)" }}>
                          {getTotalCost(selectedArray, packageData.price)}
                        </b>
                      </h5>
                    </div>
                    {givenPackageData.promoCode &&
                      givenPackageData.promoCode._id && (
                        <div>
                          <h5>
                            <b>₹&nbsp;</b>
                            <b style={{ color: "rgb(206,14,45)" }}>
                              {handleGetTotalCostAfterPromoCode(
                                selectedArray,
                                packageData.price
                              )}
                            </b>
                          </h5>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </button>
          </h2>
          <div
            id={`collapse_faq_${packageData._id}`}
            className="accordion-collapse collapse"
            aria-labelledby={`heading_faq_${packageData._id}`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div>
                <div className="d-flex justify-content-between w-100">
                  <div>
                    <div className="form-group me-2">
                      <input
                        value={promoCodeValue}
                        onChange={(e) => {
                          setPromoCodeValue(e.target.value);
                        }}
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Enter Promo Code"
                      />
                      {errMessage !== "" && (
                        <div className="m-2">
                          <span className="text-danger">{errMessage}</span>
                        </div>
                      )}
                      {givenPackageData.promoCode &&
                        givenPackageData.promoCode._id && (
                          <div className="m-2">
                            <span className="text-success">
                              promo code applied :{" "}
                              {givenPackageData.promoCode.name}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="btn btn-success"
                      onClick={() => {
                        handleApplyPromoCode();
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <div>
                  <div>
                    <p>
                      <b style={{ fontWeight: 600 }}>
                        Number of Pandit: &nbsp;
                      </b>{" "}
                      {packageData.numberOfPandit}
                    </p>
                  </div>
                  <div>
                    <p>
                      <b style={{ fontWeight: 600 }}>Samagries: &nbsp;</b>{" "}
                      {packageData.samagries}
                    </p>
                  </div>
                  <div>
                    <p>
                      <b style={{ fontWeight: 600 }}>
                        To be arranged by client: &nbsp;
                      </b>{" "}
                      {packageData.toBeArrageByClient}
                    </p>
                  </div>
                  <div>
                    <p>
                      <b style={{ fontWeight: 600 }}>Procedure: &nbsp;</b>
                      <EditedTextCmp givenState={packageData.procedure} />
                    </p>
                  </div>
                  <div>
                    <div>
                      <h5>Add Ons</h5>
                      <div>
                        {selectedArray.map((value, index) => {
                          return (
                            <div key={index}>
                              <p>
                                {value.name} ({value.price})
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <span>
                      <button
                        type="button"
                        onClick={() => {
                          handleRemoveThisPackag(packageData._id);
                        }}
                        className={`btn packageDetails_box_item_item_book_button  `}
                      >
                        Remove
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Cart = () => {
  const allPackageAddOns = useSelector((state) => state.packageAddOnsReducer);
  const { cart, addToCart } = useCart();
  const { allProducts } = useProducts();
  const { updatePackageOrders } = usePackageOrders();
  // =================================================================
  // const [currentCart, setcurrentCart] = useState("packages"); // products
  const currentCart = useSelector((state) => state.currentCartReducer);
  const setcurrentCart = (givenCart) => {
    dispatch(setCurrentCart(givenCart));
  };
  // =================================================================
  const allCustomerPackageCart = useSelector(
    (state) => state.customerPackageCartReducer
  );
  const dispatch = useDispatch();

  const [showBookNowModal, setShowBookNowModal] = useState(false);

  // ------------------
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalPackageData, setModalPackageData] = useState({});
  // ------------------

  // -----------------------------------------------------------------
  const { customerData } = useCustomerDataHook();
  const navigate = useNavigate();
  // ----------------------------------------------------------------
  const [date, setData] = useState("");
  const [puja, setPuja] = useState("");
  const [spackage, setspackage] = useState("");
  const [language, setLanguage] = useState("");
  const [addressLine2, setaddressLine2] = useState("");

  const [userState, setUserState] = useState("");
  const [city, setCities] = useState("");
  const [address, setAddress] = useState("");

  const [userName, setuserName] = useState("");
  const [anotherMobile, setanotherMobile] = useState("");
  const [email, setEmail] = useState("");
  const [addressLine1, setaddressLine1] = useState("");

  const [promoCodeValue, setPromoCodeValue] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const selectedPromoCode = useSelector(
    (state) => state.productSelectedPromoCodeReducer
  );

  const data = {
    userState,
    setUserState,
    city,
    setCities,
    date,
    setData,
    puja,
    setPuja,
    spackage,
    setspackage,
    language,
    setLanguage,
    address,
    setAddress,
    userName,
    setuserName,
    anotherMobile,
    setanotherMobile,
    email,
    setEmail,
    addressLine1,
    setaddressLine1,
    addressLine2,
    setaddressLine2,
  };

  // -----------------------------------------------------------------

  useScrollToTop();

  const getThisPromoCodes = () => {
    return allPromoCodes.filter((v) => {
      if (v.productOrPackage === "product") {
        return true;
      }
      return false;
    });
  };

  const handleApplyPromoCode = () => {
    setErrMessage("");
    const newArr = getThisPromoCodes().filter((v) => {
      if (v.code === promoCodeValue) {
        return true;
      } else {
        return false;
      }
    });

    if (newArr.length > 0) {
      const currentTotalValue = handleGetDiscountValue(
        getTotalPrice(allProducts, cart)
      );
      const minValue = newArr[0].minOrderValue;

      if (currentTotalValue < Number.parseInt(minValue)) {
        dispatch(setProductSelectedPromoCode({}));
        setErrMessage(
          `Minimum order value for this promo code is ${minValue} Rs`
        );
      } else {
        dispatch(setProductSelectedPromoCode(newArr[0]));
      }
    } else {
      dispatch(setProductSelectedPromoCode({}));
      setErrMessage("Invalid Promo Code!");
    }
  };

  const handleGetDiscountValue = (totalCost) => {
    if (selectedPromoCode._id) {
      const discountType = selectedPromoCode.discountType;
      const discountAmount = selectedPromoCode.amount;

      if (discountType === "flat") {
        return Math.ceil(
          Number.parseInt(totalCost) - Number.parseInt(discountAmount)
        );
      } else {
        return Math.ceil(
          Number.parseInt(totalCost) *
            ((100 - Number.parseInt(discountAmount)) / 100)
        );
      }
    } else {
      return Math.ceil(totalCost);
    }
  };

  const handleGetFinalPriceAfterDiscount = (totalCost) => {
    if (selectedPromoCode._id) {
      const discountType = selectedPromoCode.discountType;
      const discountAmount = selectedPromoCode.amount;

      if (discountType === "flat") {
        return Math.floor(
          totalCost -
            (Number.parseInt(totalCost) - Number.parseInt(discountAmount))
        );
      } else {
        return Math.floor(
          totalCost -
            Number.parseInt(totalCost) *
              ((100 - Number.parseInt(discountAmount)) / 100)
        );
      }
    } else {
      return Math.floor(totalCost);
    }
  };

  const isProductInCart = (productId, givenCart) => {
    let isExist = givenCart.filter((value, index) => value._id === productId);
    return isExist[0] ? true : false;
  };

  const getQuantity = (productId, givenCart) => {
    return givenCart.filter((value, index) => value._id === productId)[0]
      .quantity;
  };

  const handleUpdateQuantity = (process, productData, prevQuantity) => {
    if (process === "add") {
      let newNum = Number.parseInt(prevQuantity) + 1;
      addToCart(productData, newNum);
    } else if (process === "remove") {
      let newNum = Number.parseInt(prevQuantity) - 1;
      addToCart(productData, newNum);
    }
  };

  const getTotalPrice = (givanProducts, givenCart) => {
    const sum = givanProducts
      .filter((value) => isProductInCart(value._id, givenCart))
      .reduce(
        (accumulator, currentValue) =>
          accumulator +
          currentValue.price * getQuantity(currentValue._id, givenCart),
        0
      );
    return sum;
  };

  // =================================================================
  // package cart related functions
  const getDateTime = () => {
    const currentDate = new Date();
    const todayDate = currentDate.toLocaleDateString();
    const currentTime = currentDate.toLocaleTimeString();
    return { todayDate, currentTime };
  };

  const handleOpenBookingModal = () => {
    if (!language || language === "") {
      setLanguage(sessionStorage.getItem("language"));
    }
    if (!date || date === "") {
      setLanguage(sessionStorage.getItem("date"));
    }
    if (!puja || puja === "") {
      setPuja(sessionStorage.getItem("puja"));
    }
    if (!spackage || spackage === "") {
      setspackage(sessionStorage.getItem("spackage"));
    }

    if (!customerData.mobile) {
      sessionStorage.setItem("bookNow", "book");

      navigate("/login");
      return alert("Please Login to continue...");
    }
    if (customerData.addressObject) {
      setuserName(
        `${
          customerData.addressObject.firstName
            ? customerData.addressObject.firstName
            : ""
        } ${
          customerData.addressObject.lastName
            ? customerData.addressObject.lastName
            : ""
        }`
      );
      setanotherMobile(
        customerData.addressObject.phone ? customerData.addressObject.phone : ""
      );
      setEmail(customerData.email ? customerData.email : "");
    } else {
      setanotherMobile("");
      setuserName("");
    }

    setShowBookNowModal(true);
  };

  const bookNowFunction = () => {
    const { todayDate, currentTime } = getDateTime();

    let form_data = new FormData();
    form_data.append("packageInfo", JSON.stringify(allCustomerPackageCart));

    form_data.append("userState", userState);
    form_data.append("city", city);
    form_data.append("address", address);

    form_data.append("userName", userName);
    form_data.append("anotherMobile", anotherMobile);
    // --------------------------------------
    form_data.append("subtotal", getCustomerPackageTotalCost());
    form_data.append(
      "discount",
      getCustomerPackageTotalCost() - getCustomerPackageTotalCostAfterPromo()
    );
    form_data.append("orderTotal", getCustomerPackageTotalCostAfterPromo());

    // --------------------------------------
    form_data.append("date", date);

    form_data.append("spackage", spackage);
    form_data.append("requestDate", todayDate);
    form_data.append("requestTime", currentTime);

    fetch(
      `${process.env.REACT_APP_BACKEND_1}/customer/save_new-occasionRequest`,
      {
        method: "POST",
        body: form_data,
        headers: new Headers({
          Authorization: `Bearer ${globalVariable.accessToken}`,
        }),
      }
    )
      .then((v) => v.json())
      .then((v) => {
        if (v.message === "success") {
          setModalPackageData(v.data);
          if (sessionStorage.getItem("bookNow")) {
            sessionStorage.removeItem("bookNow");
          }
          dispatch(getAllCustomerPackageCart());
          setShowBookNowModal(false);
          setShowSuccessModal(true);
          updatePackageOrders();
          // navigate("/");
        } else {
          if (v.detail) {
            alert(v.detail);
          } else {
            alert("Something Went Wrong! Please Try again.1");
          }
        }
      })
      .catch((err) => {
        alert("something went wrong! Please Try again. 2");
        console.log(err.message);
      });
  };

  const handleRemoveThisPackag = (packageId) => {
    const formBody = new FormData();
    formBody.append("packageId", packageId);

    fetch(`${process.env.REACT_APP_BACKEND_1}/customer/package/cart/remove`, {
      method: "POST",
      body: formBody,
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
      .then((v) => v.json())
      .then((v) => {
        if (v.message === "success") {
          dispatch(getAllCustomerPackageCart());

          setTimeout(() => {
            alert("Package Removed Successfully!");
          }, 200);
        } else {
          if (v.detail) {
            alert(v.detail);
          } else {
            alert("Something Went Wrong! Please Try again.1");
          }
        }
      })
      .catch((err) => {
        alert("something went wrong! Please Try again. 2");
        console.log(err.message);
      });
  };

  // =================================================================

  const allPromoCodes = useSelector((state) => state.promoCodeReducer);

  useEffect(() => {
    if (currentCart === "") {
      dispatch(setCurrentCart("products"));
    }
    if (!sessionStorage.getItem("accessToken")) {
      alert("Please Login...");
      navigate("/login");
    }
    if (allPromoCodes.length === 0) {
      dispatch(getAllPromoCodes());
    }

    if (allCustomerPackageCart.length < 1) {
      dispatch(getAllCustomerPackageCart());
    }
  }, [
    allCustomerPackageCart.length,
    allPromoCodes.length,
    currentCart,
    dispatch,
    navigate,
  ]);

  const getCustomerPackageTotalCost = () => {
    return allCustomerPackageCart.reduce((total, currentValue) => {
      if (currentValue.package) {
        const packagePrict = total + currentValue.package.price;
        const addOnTotal = currentValue.addonArray.reduce((inTotal, num) => {
          return inTotal + Number.parseInt(num.price);
        }, 0);
        return packagePrict + addOnTotal;
      }
      return 0;
    }, 0);
  };
  const getCustomerPackageTotalCostAfterPromo = () => {
    return allCustomerPackageCart.reduce((total, currentValue) => {
      const handleGetTotalCostAfterPromoCode = (totalCost) => {
        if (currentValue.promoCode && currentValue.promoCode._id) {
          const discountType = currentValue.promoCode.discountType;
          const discountAmount = currentValue.promoCode.amount;

          if (discountType === "flat") {
            return Math.ceil(
              Number.parseInt(totalCost) - Number.parseInt(discountAmount)
            );
          } else {
            return Math.ceil(
              Number.parseInt(totalCost) *
                ((100 - Number.parseInt(discountAmount)) / 100)
            );
          }
        } else {
          return Math.ceil(totalCost);
        }
      };

      const packagePrict =
        total +
        handleGetTotalCostAfterPromoCode(
          currentValue.package ? currentValue.package.price : 0
        );
      const addOnTotal = currentValue.addonArray
        ? currentValue.addonArray.reduce((inTotal, num) => {
            return inTotal + Number.parseInt(num.price);
          }, 0)
        : 0;
      return packagePrict + addOnTotal;
    }, 0);
  };

  return (
    <div>
      <Header />
      <Banner title="Cart" showItemSlider={false} />
      <div className="container   my-3">
        <h5 className="text-primary  wishlistPage_heading">
          <Link to="/" className="text-decoration-none">
            <u>Home</u>
          </Link>
          <span> | </span>
          <span>Cart</span>
        </h5>
      </div>
      <div className="container">
        <div>
          <span className="me-3">
            <button
              className={`btn ${
                currentCart === "products" ? "btn-primary" : "btn-secondary"
              } `}
              onClick={() => {
                setcurrentCart("products");
              }}
            >
              Product Cart
            </button>
          </span>
          <span>
            <button
              className={`btn ${
                currentCart === "packages" ? "btn-primary" : "btn-secondary"
              } `}
              onClick={() => {
                setcurrentCart("packages");
              }}
            >
              Package Cart
            </button>
          </span>
        </div>
      </div>
      {currentCart === "products" && (
        <div style={{ minHeight: "60vh" }}>
          <div className="container my-5">
            <div className="row">
              <div className="col-md-8">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" className="boldTh">
                          Product
                        </th>
                        <th scope="col" className="boldTh">
                          Price
                        </th>
                        <th scope="col" className="boldTh">
                          Unit
                        </th>
                        <th scope="col" className="boldTh">
                          Quantity
                        </th>
                        <th scope="col" className="boldTh">
                          Subtotal
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {allProducts
                        .filter((value, index) =>
                          isProductInCart(value._id, cart)
                        )
                        .map((value, index) => {
                          return (
                            <tr className="cart_table-row" key={index}>
                              <td>
                                <div className="cart_align-div">
                                  <span
                                    style={{
                                      backgroundImage: `url('${value.image}')`,
                                    }}
                                    className="cart_product-img"
                                  ></span>
                                  <h6>{value.name}</h6>
                                </div>
                              </td>
                              <td>
                                <div className="cart_align-div">
                                  <h6>{value.price}</h6>
                                </div>
                              </td>
                              <td>
                                <div className="cart_align-div">
                                  <h6>
                                    {value.quaniityPerunit *
                                      getQuantity(value._id, cart)}{" "}
                                    {value.unit}
                                  </h6>
                                </div>
                              </td>
                              <td>
                                <div className="cart_align-div">
                                  <span
                                    className="product_modal_desc_Box2_sec1_body_black cart_quantity-icons"
                                    onClick={() => {
                                      handleUpdateQuantity(
                                        "remove",
                                        value,
                                        getQuantity(value._id, cart)
                                      );
                                    }}
                                  >
                                    <i className="fa fa-minus"></i>
                                  </span>
                                  <span className="product_modal_desc_Box2_sec1_body_white cart_quantity">
                                    <h6>{getQuantity(value._id, cart)}</h6>
                                  </span>
                                  <span
                                    className="product_modal_desc_Box2_sec1_body_black cart_quantity-icons"
                                    onClick={() => {
                                      handleUpdateQuantity(
                                        "add",
                                        value,
                                        getQuantity(value._id, cart)
                                      );
                                    }}
                                  >
                                    <i className="fa fa-plus"></i>
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="cart_align-div">
                                  <h6>
                                    {value.price * getQuantity(value._id, cart)}
                                  </h6>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-4">
                <h3>Cart Totals</h3>
                <div className="cart_summary_box">
                  <div className="section1">
                    <h6>Subtotal </h6>
                    <h6>
                      <span className="subtotal">
                        ₹ {getTotalPrice(allProducts, cart)}.00
                      </span>
                    </h6>
                  </div>

                  {/* <hr />
                <div className="section1">
                  <h6>Shipping Chaget </h6>
                  <h6>
                    <span className="subtotal">₹ 00.00</span>
                  </h6>
                </div> */}
                  <hr />
                  <div className="section1">
                    <div>
                      <div className="form-group me-2">
                        <input
                          value={promoCodeValue}
                          onChange={(e) => {
                            setPromoCodeValue(e.target.value);
                          }}
                          type="text"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="Enter Promo Code"
                        />

                        {errMessage !== "" && (
                          <div className="m-2">
                            <span className="text-danger">{errMessage}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn btn-success"
                        onClick={() => {
                          handleApplyPromoCode();
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                  {selectedPromoCode._id && (
                    <div className="m-2">
                      <span className="text-success">
                        promo code applied : {selectedPromoCode.name}
                      </span>
                    </div>
                  )}
                  <hr />
                  {selectedPromoCode._id && (
                    <div>
                      <div className="section1">
                        <h6>discount </h6>
                        <h6>
                          <span className="subtotal">
                            ₹{" "}
                            {handleGetFinalPriceAfterDiscount(
                              getTotalPrice(allProducts, cart)
                            ) + 0}
                            .00
                          </span>
                        </h6>
                      </div>
                      <hr />
                    </div>
                  )}
                  <div className="section1">
                    <h6>Total </h6>
                    <h6>
                      {selectedPromoCode._id ? (
                        <span className="subtotal">
                          ₹{" "}
                          {handleGetDiscountValue(
                            getTotalPrice(allProducts, cart)
                          ) + 0}
                          .00
                        </span>
                      ) : (
                        <span className="subtotal">
                          ₹ {getTotalPrice(allProducts, cart) + 0}.00
                        </span>
                      )}
                    </h6>
                  </div>
                  {cart.length > 0 ? (
                    <Link to="/checkout">
                      <button className="cart_proceed-btn my-2 p-1">
                        Proceed To Checkout
                      </button>
                    </Link>
                  ) : (
                    <Link to="/products">
                      <button className="cart_proceed-btn my-2 p-1">
                        Add Products To Cart
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {currentCart === "packages" && (
        <div style={{ minHeight: "60vh" }}>
          <div className="container my-5">
            <div className="row">
              <div className="col-md-8">
                <div className="table-responsive">
                  <h5>Package Details</h5>
                  <div>
                    <div className="accordion" id="accordionExample">
                      {allCustomerPackageCart.map((value, index) => {
                        return (
                          <RenderPackageData
                            allPromoCodes={allPromoCodes}
                            givenPackageData={value}
                            allPackageAddOns={allPackageAddOns}
                            handleRemoveThisPackag={handleRemoveThisPackag}
                            key={index}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <h3>Cart Totals</h3>
                <div className="cart_summary_box">
                  {/* <div className="section1">
                  <h6>Subtotal </h6>
                  <h6>
                    <span className="subtotal">
                      ₹ {getCustomerPackageTotalCost()}.00
                    </span>
                  </h6>
                </div> */}
                  {/* <hr />
                <div className="section1">
                  <h6>Shipping Charges </h6>
                  <h6>
                    <span className="subtotal">₹ 00.00</span>
                  </h6>
                </div>
                <hr /> */}
                  <div className="section1">
                    <h6>Total </h6>
                    <h6>
                      <span className="subtotal">
                        ₹ {getCustomerPackageTotalCost() + 0}.00
                      </span>
                    </h6>
                  </div>
                  {getCustomerPackageTotalCostAfterPromo() !==
                    getCustomerPackageTotalCost() && (
                    <div>
                      <div className="section1">
                        <h6>Total Discount : </h6>
                        <h6>
                          <span className="subtotal">
                            ₹{" "}
                            {getCustomerPackageTotalCost() -
                              getCustomerPackageTotalCostAfterPromo()}
                            .00
                          </span>
                        </h6>
                      </div>
                      <div className="section1">
                        <h6>Final Price : </h6>
                        <h6>
                          <span className="subtotal">
                            ₹ {getCustomerPackageTotalCostAfterPromo()}
                            .00
                          </span>
                        </h6>
                      </div>
                    </div>
                  )}

                  {allCustomerPackageCart.length > 0 ? (
                    <button
                      className="cart_proceed-btn my-2 p-1"
                      onClick={() => {
                        handleOpenBookingModal();
                      }}
                    >
                      Book Now
                    </button>
                  ) : (
                    // </Link>
                    <button className="cart_proceed-btn my-2 p-1">
                      Add Packages To Cart
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <BookNowModal
        visibleDateModal={showBookNowModal}
        setvisibleDateModal={setShowBookNowModal}
        data={data}
        bookNowFunction={bookNowFunction}
      />
      <PackageOrderPlacedCmp
        isOrderPlaced={showSuccessModal}
        setIsOrderPlaced={setShowSuccessModal}
        modalPackageData={modalPackageData}
      />
      <Footer />
    </div>
  );
};

export default Cart;
