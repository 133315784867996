import { SET_ORDERS } from "../constants";

const allOrdersData = [];
export const orderReducer = (state = allOrdersData, action) => {
  switch (action.type) {
    case SET_ORDERS:
      return action.data.data;
    default:
      return state;
  }
};
